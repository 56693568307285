import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Redemptions from "./components/pages/Redemptions"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Alliances from "./components/pages/Alliances"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice, sendDataLayer } from './utils/functions'
import { doRedeem } from './utils/helpers'
import useWidth from './hooks/useWidth'

import './styles/main.scss'
import VerticalCenter from './layouts/VerticalCenter/VerticalCenter'
import LoginProgressBar from './components/atoms/LoginProgressBar'
import Button from './components/atoms/Button'
import Positions from './components/pages/Positions'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const { width } = useWidth();
  const [ pages, setPages] = useState([])
  const [succesIns, setSuccesIns] = useState(false)
  
  
  let home_page = '/mecanica'

  const handleNavigate = () => {
    window.location.href = home_page
  }

  useEffect(() => {
    if(width > 992) {
      setPages([
        {page: 'progreso', title: 'Misiones', icon: 'misiones', sublevel: false, component: <Progress privatePage />},
        {page: 'posiciones', title: 'Posiciones', icon: 'posiciones', sublevel: false, component: <Positions privatePage />},
        {page: 'premios', title: 'Premios', icon: 'recompensas', sublevel: false, component: <Awards privatePage />},
        {page: 'alianzas', title: 'Beneficios', icon:'beneficios', sublevel: false, component: <Alliances privatePage />},
        {page: 'mis-redenciones', title: 'Redenciones', icon: 'redenciones', hide: (context.tracing && (context.tracing[`winner_1`] === 0 && context.tracing[`winner_2`] === 0) || !context.tracing), sublevel: false, component: <Redemptions privatePage /> },
        {page: 'mas', title: 'Otros', icon: 'otros', sublevel: true, children: [
          {page: 'mecanica', title: '¿Cómo participar?', icon: 'mecanica', sublevel: false, component: <Mechanic privatePage />},
          {page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc', component: <Terms loggedIn={loggedIn} />},
          {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faq2', component: <Faqs loggedIn={loggedIn}/>}
        ]}
      ])
    } else {
      setPages([
        {page: 'progreso', title: 'Misiones', icon: 'misiones', sublevel: false, component: <Progress privatePage />},
        {page: 'posiciones', title: 'Posiciones', icon: 'posiciones', sublevel: false, component: <Positions privatePage />},
        {page: 'premios', title: 'Premios', icon: 'recompensas', sublevel: false, component: <Awards privatePage />},
        {page: 'alianzas', title: 'Beneficios', icon:'beneficios', sublevel: false, component: <Alliances privatePage />},
        {page: 'mas', title: 'Otros', icon: 'otros', sublevel: true, children: [
          {page: 'mecanica', title: '¿Cómo participar?', icon: 'mecanica', component: <Mechanic privatePage />},
          {page: 'mis-redenciones', title: 'Redenciones', icon: 'redenciones', hide: (context.tracing && (context.tracing[`winner_1`] === 0 && context.tracing[`winner_2`] === 0) || !context.tracing), sublevel: false, component: <Redemptions privatePage /> },
          {page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc', component: <Terms loggedIn={loggedIn} />},
          {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faq2', component: <Faqs loggedIn={loggedIn}/>},
        ]}
      ])
    }
  }, [width, context])

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout( () => {
        localStorage.removeItem('userNotFound')
      }, 3000 )
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', '') )
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', '') )
          if (public_child_url?.component?.props?.privatePage) navigate('/')
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      gtUser(token).then((user) => {

        if (user?.data) {
          setContext(user.data)

          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            if (
              user?.data && 
              user?.data?.firstTime && 
              new Date() < new Date("2025-04-11")
            ) {
              sendDataLayer({datalayer: {event: 'start_participation', text_title: '¡Ya tienes 25 puntos de ventaja!' } ,repeat: true})
              setSuccesIns(true);
              setTimeout( () => {
                window.location.href = home_page
              }, 5000 )
            } else if (user?.data && !user?.data?.firstTime) {
              home_page = '/progreso';
              window.location.href = home_page
            }
          }
        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() =>  navigate('/'))

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: awardSelected.name ,product_price: awardSelected.price, product_type: "inteligentes", index: awardSelected.index},repeat: true})
    }
    setShowConfirm(!showConfirm)

  }

  const handlerRedemtion = async () => {
    setRedemtionLoading(true)

    if (awardSelected.canRedem) {
      doRedeem(token, awardSelected.price, awardSelected.id).then((data) => {
        console.log("data", data)
        setRedemtionLoading(false)
        setShowConfirm(false)

        if (data && data.status === 200 && data.file) {
          setShowSuccessRedemtion(true)
          sendDataLayer({datalayer: {event: 'redeem_bond', product_name: awardSelected.name, product_price: awardSelected.price, product_type: "inteligentes", index: awardSelected.index},repeat: true})

          setTimeout( () => {
            window.location.reload()
          }, 2000 )
        } else {
          console.log("Error redemtion!", data)

          if (data.status === 304) {
            setShowConfirm(true)
          } else {
            sendDataLayer({datalayer: {event: 'exhausted_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index},repeat: true})
            setShowErrorRedemtion(true)
          }
          
        }
      }).catch(error => {
        console.log("ERROR ON REDENTION:", error)
      })
    }
  }

  return (
    <StateController.Provider
      value = {
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        title="Datos incorrectos"
        desc="Verifique su información y vuelva a intentarlo."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        setShowModal={setShowConfirm}
        redemtion={true}
        image={awardSelected.image}
        title={`${getPrice(awardSelected.price)}`}
        desc={awardSelected.name}
        position={awardSelected.index}
        awardSelected={awardSelected}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar"
          }
        }
        terms={
          {
            termsText: "Los bonos digitales están sujetos a términos y condiciones de cada comercio, los cuales se especifican en el bono digital que se redime y se descarga de la plataforma.",
            termsLink: ""
          }
        }
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />
      <CustomModal
        title="Redención correcta"
        desc={`Su bono ha sido descargado. Encuéntrelo en la parte inferior de la sección “Premios”.`}
        cta="Aceptar"
        info={true}
        nologo={true}
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
      />

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'}`}>
      <Header
          headerType={1}
          logo="logo_davivienda.png"
          items={pages}
          submenu={false}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          <Route path="*" element={<NotFound loggedIn={loggedIn} />} />
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="footer_cards.png"
          secondaryFooterImg="footer_cards.png"
          bankFooterImg="bank-footer.png"
          awardFooterImg="award.png"
          links={
            [
              {
                link: "/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          linksText="Válido del 24 de abril de 2025 al 23 de junio de 2025."
          banco="Banco Davivienda - Todos los derechos reservados 2025"
          content="<p>Recuerde que por su seguridad Banco Davivienda nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares. Consulte tasas y tarifas, reglamento, términos y condiciones del producto en <a href='https://www.davivienda.com/' target= _blank rel='noreferrer'> www.davivienda.com</a></p>"
          copyright="© Copyright 2024"

        />
        {succesIns && 
          <div className={`mc-preloader-container mc-preloader-container__fullscreen`}>
            <img src={`/assets/images/logo_davivienda.png`} alt="Logo Banco Davivienda" />
            <VerticalCenter>
              <LoginProgressBar />
              <img src={`/assets/images/gif.gif`} alt="Gif user" className='gif'/>
              <p className='rojo'>¡Ya tiene 25 puntos de ventaja!</p>
              <p className='negro'>Siga aprovechando cada oportunidad para subir en la tabla de posiciones.<strong>¡Buen comienzo!</strong></p>
              <div className='login-button-container'>
                <Button
                  type="primary"
                  text="Empezar a participar"
                  loading={false}
                  status="active"
                  onClick={handleNavigate}
                />
              </div>
            </VerticalCenter>
          </div>
        }
      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)