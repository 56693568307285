import React, { useState, useContext, useEffect } from 'react'
import Icon from '../atoms/Icon'
import { StateController } from './../../App'
import Preloader from '../atoms/Preloader'
import Button from "../atoms/Button";
import useWidth from '../../hooks/useWidth'
import { sendDataLayer } from "../../utils/functions";

function Positions() {

  const [showAct, setShowAct] = useState(false)
  const [showStatus ] = useState(true)
  const [winnerMision1, setWinnerMision1] = useState(false)
  const [winnerMisionInsc, setWinnerMisionInsc] = useState(false)
  const [winner1, setWinner1] = useState(false)
  const [goalsCount, setGoalsCount] = useState(0)
  const {context} = useContext(StateController)
  const { width } = useWidth();

  const togglerAct = () => {
    sendDataLayer({datalayer: {event: 'ranking_center', text: 'Conozca el registro de sus actividades', link: '' }, repeat: true})
    setTimeout( () => {
      setShowAct(!showAct)
    }, 50 )
  }

  useEffect(() => {
    let formattedDate;
    if(context) {
      const [day, month, year] = context.firstLoginDate.split("/");
      formattedDate = new Date(`${year}-${month}-${day}`);
    }

    if((context.tracing && context.user) && context.tracing[`amount_1`] >= context.user[`goal_amount_1`] && context.tracing[`trx_1`] >= context.user[`goal_trx_1`]) {
      setWinnerMision1(true);
    }
    if (context && new Date(formattedDate) < new Date("2025-04-11")) {
      setWinnerMisionInsc(true)
    } 
    if ((context.tracing)) {
      setGoalsCount(context.tracing[`goal_count`])
      if(context.tracing[`winner_1`] === 1)
        setWinner1(true)
    }
  }, [context])

  return (
    <div className='mc-page progress slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Explore sus misiones</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Complete las misiones, sume puntos y podrá <strong>ganar viajes</strong> o bonos digitales de hasta $7.000.000.</span>
      </div>
      {context
      ? <div className= {`progress-container ${(showStatus || showAct) ? 'active' : ''}`}>
          <div className= 'puntaje-container'>
            <div className='puntaje-title'>
              <svg xmlns="http://www.w3.org/2000/svg" width="47" height="56" viewBox="0 0 47 56" fill="none">
                <path d="M-41.9506 27.5251C-46.7721 20.3527 -42.3798 14.5999 -37.5986 12.6673C-22.5352 6.6148 25.4599 -7.81117 39.5172 6.18941C54.0965 19.5774 41.8316 69.3942 36.1928 84.5959C32.9517 93.2963 25.5494 90.4408 20.092 87.1488C0.0844234 74.9639 -23.2043 55.3219 -41.9506 27.5251Z" fill="#FFC5C8"/>
              </svg>
              <h4>
                <img src={`/assets/images/puntos.png`} alt={'puntos img'} /> Puntaje
              </h4>
            </div>
            <div className='puntaje-desc'>
              <div className='profile-container'>
                <img src='/assets/images/gif.gif' alt='profile img'/>
                <div className='puntaje-points-container'>
                  <p className='points'>
                    {context.tracing ? (context.tracing[`points`]).toLocaleString("es-CO") : (!context.tracing && winnerMisionInsc) ?  25 : 0}
                  </p>
                  <p className='points-text'>
                    Puntos acumulados
                  </p>
                </div>
              </div>
              <div className='actividades-container'>
                <div className="toggle" onClick={togglerAct} >
                  <div className='toggle-text'>
                    Conozca el registro de sus actividades
                  </div>
                  <div className={`toggle-icon ${showAct ? 'active' : ''}`}>
                    {!showAct 
                    ? <Icon name="progressCloseSmall" />
                    : <Icon name="progressOpenSmall" />
                    }
                  </div>
                </div>
                {showAct &&
                  <div className="mc-accordeon-content">
                    {winnerMision1 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          1000 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión 1<br/>
                        </div>
                      </div>
                    }
                    {goalsCount > 0 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          {100 * goalsCount} puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión 2<br/> 
                        </div>
                      </div>
                    }
                    {winner1 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          40 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión billetera digital <br/> 
                        </div>
                      </div>
                    }
                    {winnerMisionInsc &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          25 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión inscripción <br/> 
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
              <div className='actualizacion-container'>
                <span>
                  Actualización de puntaje y estatus completada el {(context.tracing  && context.tracing[`date_update`]) ? context.tracing[`date_update`] : '' }
                </span>
              </div>
            </div>
          </div>
          <div className='misiones-status-container'>
            <div className='misiones-container'>
              <div className={`misiones-title ${showStatus ? 'active' : ''}`}>
                {!showStatus 
                ? width > 992 
                  ? <svg xmlns="http://www.w3.org/2000/svg" width="130" height="72" viewBox="0 0 130 72" fill="none">
                      <path d="M-43.8007 53.686C-53.2416 39.6417 -44.6411 28.3774 -35.2791 24.5932C-5.78381 12.7419 88.1945 -15.5053 115.72 11.909C144.267 38.1238 120.252 135.669 109.21 165.435C102.864 182.471 88.3697 176.88 77.6837 170.434C38.5072 146.575 -7.094 108.114 -43.8007 53.686Z" fill="#FFC5C8"/>
                    </svg>
                  : <svg xmlns="http://www.w3.org/2000/svg" width="130" height="102" viewBox="0 0 130 102" fill="none">
                      <path d="M-43.8007 53.8149C-53.2416 39.7706 -44.6411 28.5063 -35.2791 24.7221C-5.78381 12.8708 88.1945 -15.3764 115.72 12.0379C144.267 38.2527 120.252 135.798 109.21 165.564C102.864 182.6 88.3697 177.009 77.6837 170.563C38.5072 146.704 -7.094 108.243 -43.8007 53.8149Z" fill="#FFC5C8"/>
                    </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" width="47" height="56" viewBox="0 0 47 56" fill="none">
                    <path d="M-41.9506 27.5251C-46.7721 20.3527 -42.3798 14.5999 -37.5986 12.6673C-22.5352 6.6148 25.4599 -7.81117 39.5172 6.18941C54.0965 19.5774 41.8316 69.3942 36.1928 84.5959C32.9517 93.2963 25.5494 90.4408 20.092 87.1488C0.0844234 74.9639 -23.2043 55.3219 -41.9506 27.5251Z" fill="#FFC5C8"/>
                  </svg>
                }
                
                <h4 className={`${!showStatus && 'close'}`}>
                  <img src={`/assets/images/status.png`} alt={'puntos img'} /> {width > 992 ? "Estatus de posición" : <>Estatus <br /> de posición</>}
                </h4>
              </div>
              {showStatus &&
                <div className='misiones-desc type-2'>
                  <div className='status-container'>
                    {context && context.tracing === null
                    ? <>
                        <img src={`/assets/images/status (2).png`} alt={'status img'} />
                        <div className='title-container'>
                          <p className='title'>
                            ¡Todo está por definirse!
                          </p>
                          <p className='desc'>
                            A medida que la competencia avance, los grupos por puntaje se activarán. Sume puntos para quedar entre las primeras 1500 posiciones.
                          </p>
                        </div>
                      </>
                    : context && context.tracing && context.tracing[`idmask`] === "A33333"
                      ? <>
                          <img src={`/assets/images/status-final-ganador.png`} alt={'status final img'} />
                          <div className='title-container'>
                            <p className='desc type-3'>
                            ¡Felicitaciones!<br/> Quedó en la posición <strong>{`<${context && context.tracing && context.tracing[`position`] ?  context.tracing[`position`] : ''}>`}</strong> con <strong>{`<${context && context.tracing && context.tracing[`points`] ?  context.tracing[`points`] : ''}>`}</strong> puntos.<br/><strong>Pronto nos contactáremos</strong> para que pueda reclamar su premio
                            </p>
                          </div>
                        </>
                      : context && context.tracing && context.tracing[`idmask`] === "A44444"
                        ? <>
                            <img src={`/assets/images/status-final-ganador.png`} alt={'status final img'} />
                            <div className='title-container'>
                              <p className='desc type-3'>
                                <strong>¡Lo logró!</strong><br/><br/>Quedó en la posición <strong>{`<${context && context.tracing && context.tracing[`position`] ?  context.tracing[`position`] : ''}>`}</strong> con <strong>{`<${context && context.tracing && context.tracing[`points`] ?  context.tracing[`points`] : ''}>`}</strong> puntos. ¿Quiere conocer su premio?
                              </p>
                              <div className='button-container'>
                                <Button
                                  type="primary"
                                  text="Ir por mi premio"
                                  loading={false}
                                  status={`${context && context.redemtions && context.redemtions.length === 2 ? 'inactive' : 'active'}`}
                                  link="/mis-redenciones"
                                  premioFinal={true}
                                />
                              </div>
                            </div>
                          </>
                        : context && context.tracing && context.tracing[`idmask`] === "A55555"
                          ? <>
                              <img src={`/assets/images/status-final-no-ganador.png`} alt={'status final img'} />
                              <div className='title-container'>
                                <p className='desc type-3'>
                                <strong>¡Gracias por participar!</strong><br/><br/>No alcanzó el top de premios esta vez. Espere la revancha en la próxima Misión Debitazo.
                                </p>
                              </div>
                            </>
                          :<>
                            <div className='title-container'>
                              <p className='desc type-2'>
                                Descubra cómo va la competencia, sume más puntos y avance en el grupo de ganadores. 
                              </p>
                              <div className='puntajes'>
                                <div>
                                  {context && context.tracing && context.tracing[`points`] >= 3806 &&
                                    <div className='info-pos'>
                                      <span> ¡Está en el Top 5! La meta está cerca, sume más puntos.</span>
                                    </div>
                                  }
                                  <div className='puntajes-item'>
                                    <span>{`<3806 puntos> - <4105 puntos>`}</span>
                                  </div>
                                </div>
                                <div>
                                  {context && context.tracing && context.tracing[`points`] >= 2505 && context.tracing[`points`] < 3806 &&
                                    <div className='info-pos'>
                                      <span>¡Está en el Top 30! Aún hay oportunidad de avanzar.</span>
                                    </div>
                                  }
                                  <div className='puntajes-item'>
                                    <span>{`<2506 puntos> - <3805 puntos>`}</span>
                                  </div>
                                </div>
                                <div>
                                  {context && context.tracing && context.tracing[`points`] >= 1206 && context.tracing[`points`] < 2505 &&
                                    <div className='info-pos'>
                                      <span>¡Está en el Top 1465! Sume puntos y avance en el grupo.</span>
                                    </div>
                                  }
                                  <div className='puntajes-item'>
                                    <span>{`<1206 puntos> - <2505 puntos>`}</span>
                                  </div>
                                </div>
                                <div>
                                  {context && context.tracing && context.tracing[`points`] >= 1000 && context.tracing[`points`] < 1206 &&
                                    <div className='info-pos'>
                                      <span>Esta es su posición actual,<br/> sume más puntos para subir de lugar.</span>
                                    </div>
                                  }
                                  <div className='puntajes-item'>
                                    <span>{`<1000 puntos> - <1205 puntos>`}</span>
                                  </div>
                                </div>
                                <div>
                                  {context && context.tracing && context.tracing[`points`] < 1000 && 
                                    <div className='info-pos'>
                                      <span>Esta es su posición actual,<br/> sume más puntos para subir de lugar.</span>
                                    </div>
                                  }
                                
                                  <div className='puntajes-item'>
                                    <span>{`<25 puntos> - <999 puntos>`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                    }
                    
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      : <Preloader
          type="section"
          invert
        />
      }
    </div>
  )
}
export default Positions