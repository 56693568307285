import React, { useState, useContext, useEffect } from 'react'
import Icon from '../atoms/Icon'
import ProgressBar from '../atoms/ProgressBar'
import { StateController } from './../../App'
import Preloader from '../atoms/Preloader'
import { getPrice, getProgressPercentPerBlock } from '../../utils/functions'
import Button from "../atoms/Button";
import TestQuestions from '../organisms/TestQuestions'
import WalletModal from '../molecules/WalletModal'
import useWidth from '../../hooks/useWidth'
import { sendDataLayer } from "../../utils/functions";
import CustomModal from '../molecules/CustomModal'

function Progress() {

  const [showAct, setShowAct] = useState(false)
  const [showMisiones ] = useState(true)
  const [winnerMision1, setWinnerMision1] = useState(false)
  const [winnerMisionInsc, setWinnerMisionInsc] = useState(false)
  const [winner1, setWinner1] = useState(false)
  const [goalsCount, setGoalsCount] = useState(0)
  const {context} = useContext(StateController)
  const [showModal, setShowModal] = useState(false);
  const { width } = useWidth();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showWinnerModal, setShowWinnerModal] = useState(false);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedOption) {
      setSubmitted(true);
    }
  };

  const tabs = [
    { 
      label: 'Apple Pay', 
      OS: 'IOS',
      desc:'<div class="instrucciones"> <ol> <li>Abra la app Wallet en su iPhone, toque el "+" y seleccione “Agregar tarjeta de débito o crédito”.</li><li>Escanee su tarjeta o ingrese los datos manualmente.</li><li>Siga las instrucciones para verificar su tarjeta y listo.</li></ol></div>'
    },
    { 
      label: 'Google Wallet', 
      OS: 'Android',
      desc:'<div class="instrucciones"> <ol> <li>Abra la app Wallet en su celular, toque el "Agregar a Wallet" y  seleccione "Tarjeta de pago".</li><li>Escanee su tarjeta o ingrese los datos manualmente.</li><li>Siga las instrucciones para verificar su tarjeta y listo.</li></ol></div>'
    }
  ]

  const handleTabClick = (idx, tab) => {
    sendDataLayer({datalayer: {event: 'mission_center', text: tab.label, link: '' }, repeat: true})
    setTabIndex(idx);
  }

  const togglerAct = () => {
    sendDataLayer({datalayer: {event: 'mission_center', text: 'Conozca el registro de sus actividades', link: '' }, repeat: true})
    setTimeout( () => {
      setShowAct(!showAct)
    }, 50 )
  }

  const handleShowModal = () => {
    sendDataLayer({datalayer: {event: 'mission_center', text: 'cómo registrar su tarjeta', link: '' }, repeat: true})
    setShowModal(true); // Muestra el modal
  };

  useEffect(() => {
    let formattedDate;
    if(context) {
      const [day, month, year] = context.firstLoginDate.split("/");
      formattedDate = new Date(`${year}-${month}-${day}`);
      if(context.user) {
        if(context.user[`idmask`] === 'A33333'){
          setShowWinnerModal(true);
        }
      }
    }

    if((context.tracing && context.user) && context.tracing[`amount_1`] >= context.user[`goal_amount_1`] && context.tracing[`trx_1`] >= context.user[`goal_trx_1`]) {
      setWinnerMision1(true);
    }
    if (context && new Date(formattedDate) < new Date("2025-04-11")) {
      setWinnerMisionInsc(true)
    } 
    if ((context.tracing)) {
      setGoalsCount(context.tracing[`goal_count`])
      if(context.tracing[`winner_1`] === 1)
        setWinner1(true)
    }
  }, [context])

  return (
    <div className='mc-page progress slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Explore sus misiones</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Complete las misiones, sume puntos y podrá <strong>ganar viajes</strong> o bonos digitales de hasta $7.000.000.</span>
      </div>
      {context
      ? <div className= {`progress-container ${(showMisiones || showAct) ? 'active' : ''}`}>
          <div className= 'puntaje-container'>
            <div className='puntaje-title'>
              <svg xmlns="http://www.w3.org/2000/svg" width="47" height="56" viewBox="0 0 47 56" fill="none">
                <path d="M-41.9506 27.5251C-46.7721 20.3527 -42.3798 14.5999 -37.5986 12.6673C-22.5352 6.6148 25.4599 -7.81117 39.5172 6.18941C54.0965 19.5774 41.8316 69.3942 36.1928 84.5959C32.9517 93.2963 25.5494 90.4408 20.092 87.1488C0.0844234 74.9639 -23.2043 55.3219 -41.9506 27.5251Z" fill="#FFC5C8"/>
              </svg>
              <h4>
                <img src={`/assets/images/puntos.png`} alt={'puntos img'} /> Puntaje
              </h4>
            </div>
            <div className='puntaje-desc'>
              <div className='profile-container'>
                <img src='/assets/images/gif.gif' alt='profile img'/>
                <div className='puntaje-points-container'>
                  <p className='points'>
                    {context.tracing ? (context.tracing[`points`]).toLocaleString("es-CO") : (!context.tracing && winnerMisionInsc) ?  25 : 0}
                  </p>
                  <p className='points-text'>
                    Puntos acumulados
                  </p>
                </div>
              </div>
              <div className='actividades-container'>
                <div className="toggle" onClick={togglerAct} >
                  <div className='toggle-text'>
                    Conozca el registro de sus actividades
                  </div>
                  <div className={`toggle-icon ${showAct ? 'active' : ''}`}>
                    {!showAct 
                    ? <Icon name="progressCloseSmall" />
                    : <Icon name="progressOpenSmall" />
                    }
                  </div>
                </div>
                {showAct &&
                  <div className="mc-accordeon-content">
                    {winnerMision1 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          1000 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión 1<br/>
                        </div>
                      </div>
                    }
                    {goalsCount > 0 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          {100 * goalsCount} puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión 2<br/> 
                        </div>
                      </div>
                    }
                    {winner1 &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          40 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión billetera digital <br/> 
                        </div>
                      </div>
                    }
                    {winnerMisionInsc &&
                      <div className='actividades-item'>
                        <div className='actividades-item__points'>
                          25 puntos
                        </div>
                        <div className='actividades-item__desc'>
                          Misión inscripción <br/> 
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
              <div className='actualizacion-container'>
                <span>
                  Actualización de puntaje y estatus completada el {(context.tracing  && context.tracing[`date_update`]) ? context.tracing[`date_update`] : '' }
                </span>
              </div>
            </div>
          </div>
          <div className='misiones-status-container'>
            <div className='misiones-container'>
              <div className={`misiones-title ${showMisiones ? 'active' : ''}`}>
                {!showMisiones 
                ? width > 992 
                  ? <svg xmlns="http://www.w3.org/2000/svg" width="130" height="72" viewBox="0 0 130 72" fill="none">
                      <path d="M-43.8007 53.686C-53.2416 39.6417 -44.6411 28.3774 -35.2791 24.5932C-5.78381 12.7419 88.1945 -15.5053 115.72 11.909C144.267 38.1238 120.252 135.669 109.21 165.435C102.864 182.471 88.3697 176.88 77.6837 170.434C38.5072 146.575 -7.094 108.114 -43.8007 53.686Z" fill="#FFC5C8"/>
                    </svg>
                  : <svg xmlns="http://www.w3.org/2000/svg" width="130" height="102" viewBox="0 0 130 102" fill="none">
                      <path d="M-43.8007 53.8149C-53.2416 39.7706 -44.6411 28.5063 -35.2791 24.7221C-5.78381 12.8708 88.1945 -15.3764 115.72 12.0379C144.267 38.2527 120.252 135.798 109.21 165.564C102.864 182.6 88.3697 177.009 77.6837 170.563C38.5072 146.704 -7.094 108.243 -43.8007 53.8149Z" fill="#FFC5C8"/>
                    </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" width="47" height="56" viewBox="0 0 47 56" fill="none">
                    <path d="M-41.9506 27.5251C-46.7721 20.3527 -42.3798 14.5999 -37.5986 12.6673C-22.5352 6.6148 25.4599 -7.81117 39.5172 6.18941C54.0965 19.5774 41.8316 69.3942 36.1928 84.5959C32.9517 93.2963 25.5494 90.4408 20.092 87.1488C0.0844234 74.9639 -23.2043 55.3219 -41.9506 27.5251Z" fill="#FFC5C8"/>
                  </svg>
                }
                <h4 className={`${!showMisiones && 'type-1'} ${!showMisiones && 'close'}`}>
                  <img src={`/assets/images/misiones.png`} alt={'puntos img'} /> Misiones
                </h4>
                {!showMisiones && !submitted && context && context.user[`idmask`] === 'A55555' &&
                  <p className='available'>
                    Trivia sin responder
                  </p>
                }
              </div>
              {showMisiones &&
                <div className='misiones-desc'>
                  {!winnerMision1 &&
                    <div className='despegue-container'>
                      <div className='img-container'>
                        <img src={`/assets/images/encurso.png`} alt={'despegue img'} />
                        <div className='title-container'>
                          <p className='misionName'>
                            Misión 1
                          </p>
                          <p className='points'>
                            Gane 1000 puntos
                          </p>
                          <p className='info'>
                            por completar esta misión
                          </p>
                        </div>
                      </div>
                      <div className={`barInfo-container`}>
                        <div className='barInfo'>
                          <div className='barInfo--head'>
                            <Icon name="shop" /> Compras
                          </div>
                          <div className='barInfo--row'>
                            <span>
                              <strong>Lleva:</strong> {getPrice(context.tracing ? context.tracing[`amount_1`] : 0)}
                            </span>
                            <span>
                              <strong>Meta:</strong> {getPrice(context.user ? context.user[`goal_amount_1`] : 0)}
                            </span>
                          </div>
                          <ProgressBar
                            percent={getProgressPercentPerBlock(context, 'amount', 1)}
                            label={`${getProgressPercentPerBlock(context, 'amount', 1)}%`}
                          />
                        </div>
                        <div className='barInfo'>
                          <div className='barInfo--head'>
                            <Icon name="trxs" /> Transacciones
                          </div>
                          <div className='barInfo--row'>
                            <span>
                              <strong>Lleva:</strong> {context.tracing ? context.tracing[`trx_1`] : 0}
                            </span>
                            <span>
                              <strong>Meta:</strong> {context.user ? context.user[`goal_trx_1`] : 0}
                            </span>
                          </div>
                          <ProgressBar
                            percent={getProgressPercentPerBlock(context, 'trx', 1)}
                            label={`${getProgressPercentPerBlock(context, 'trx', 1)}%`}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  { !winnerMision1
                  ? <div className='despegue-container'>
                      <div className='img-container'>
                        <div className='title-container'>
                          <img src={`/assets/images/ascenso.png`} alt={'ascenso img'} />
                          <p className='misionName'>
                            Misión 2
                          </p>
                          <p className='points'>
                            Gane 100 puntos
                          </p>
                          <p className='info'>
                            Cuantas más veces complete esta misión, más puntos podrá sumar.
                          </p>
                        </div>
                      </div>
                      <div className={`wallets-container type-2`}>
                        <div className='trivias-title-container'>
                          <p className='available'>
                            Se activa al completar la primera misión
                          </p>
                        </div>
                        <div className='img-container type-4'>
                          <img src={`/assets/images/candado.png`} alt={'candado img'} />
                        </div>
                      </div>
                    </div>
                  : <div className='despegue-container'>
                      <div className='img-container'>
                        <img src={`/assets/images/encurso.png`} alt={'despegue img'} />
                        <div className='title-container'>
                          <p className='misionName'>
                            Misión 2
                          </p>
                          <p className='points'>
                            Gane 100 puntos
                          </p>
                          <p className='info'>
                            Cuantas más veces complete esta misión, más puntos podrá sumar.
                          </p>
                        </div>
                      </div>
                      <div>
                        {goalsCount >= 1 && 
                          <div className='info-pos'>
                            <span>Ha cumplido esta misión <br/>{`<${goalsCount}>`} veces</span>
                          </div>
                        }
                        <div className={`barInfo-container`}>
                          <div className='barInfo'>
                            <div className='barInfo--head'>
                              <Icon name="shop" /> Compras
                            </div>
                            <div className='barInfo--row'>
                              <span>
                                <strong>Lleva:</strong> {getPrice(context.tracing ? context.tracing[`amount_2`] : 0)}
                              </span>
                              <span>
                                <strong>Meta:</strong> {getPrice(context.user ? context.user[`goal_amount_2`] : 0)}
                              </span>
                            </div>
                            <ProgressBar
                              percent={getProgressPercentPerBlock(context, 'amount', 2)}
                              label={`${getProgressPercentPerBlock(context, 'amount', 2)}%`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {!winner1 && context && context.user[`idmask`] !== 'A55555'
                    ? <div className='despegue-container'>
                        <div className='img-container'>
                          <img src={`/assets/images/misionwallet.png`} alt={'wallet img'} />
                          <div className='title-container'>
                            <p className='misionName'>
                              Misión: Billetera digital
                            </p>
                            <p className='points'>
                              Gane 40 puntos
                            </p>
                            <p className='info'>
                              por completar el reto.
                            </p>
                          </div>
                        </div>
                        <div className={`wallets-container type-2`}>
                          <div className='trivias-title-container'>
                            <p className='available'>
                              Se activa el 22 de abril
                            </p>
                          </div>
                          <div className='img-container type-4'>
                            <div className='title-container type-3'>
                              <p className='info type-3'>
                                ¿Quiere ganar ventaja? conozca <a onClick={handleShowModal}>cómo registrar su tarjeta</a> según su sistema operativo
                              </p>
                            </div>
                            <img src={`/assets/images/candado.png`} alt={'candado img'} />
                          </div>
                        </div>
                      </div>
                    : !winner1 && context && context.user[`idmask`] === 'A55555'
                      ? <div className='despegue-container'>
                          <div className='img-container'>
                            <img src={`/assets/images/misionwallet-pendiente.png`} alt={'wallet img'} />
                            <div className='title-container'>
                              <div className='title-container'>
                                <p className='misionName'>
                                  Misión: Billetera digital
                                </p>
                                <p className='points'>
                                  Gane 40 puntos
                                </p>
                                <p className='info'>
                                  por completar el reto.
                                </p>
                              </div>
                              <p className='desc type-2'>
                              Registre su Tarjeta Débito Davivienda Mastercard en su billetera digital y haga una compra de al menos $50.000<br/><strong>Los primeros 2.500 participantes</strong> en cumplir la misión se podrán llevar un bono de <strong>$30.000</strong>
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className={`wallets-container`}>
                              <p className='title'>
                                Siga los pasos para registrar su tarjeta según su sistema operativo:
                              </p>
                              <div className='tabs-container'>
                                <section className="section-tab-login">
                                  <article>
                                    <div className="tab items">
                                      {tabs.map((tab, idx) => (
                                        <div
                                          key={tab.label}
                                          className={`tab-item ${tabIndex === idx ? 'active' : ''}`}
                                          onClick={() => handleTabClick(idx, tab)}
                                          role="button"
                                          tabIndex={0}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') handleTabClick(tab.key);
                                          }}
                                          aria-selected={tabIndex === idx}
                                        >
                                          <h3>
                                            {tab.label}
                                          </h3>
                                          <span>
                                            {tab.OS}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </article>
                                </section>
                              </div>
                              <div dangerouslySetInnerHTML={{ __html: tabs[tabIndex].desc}} />
                            </div>
                            <p className='note'>
                              *Lorem ipsum celular no disponible
                              para realizar la misión billetera digital
                            </p>
                          </div>
                        </div>
                      : context && context.block === 1 &&
                        <div className='despegue-container'>
                          <div className='img-container'>
                            <img src={`/assets/images/completa.png`} alt={'ascenso img'} />
                            <div className='title-container'>
                              <p className='misionName'>
                              Misión Billetera digital<br/> cumplida
                              </p>
                            </div>
                          </div>
                          <div className='img-container'>
                            <div className='title-container type-2'>
                              <p className='points'>
                                Ganó 40 puntos
                              </p>
                              <p className='info'>
                                premio extra: bono de <red>$30.000</red>
                              </p>
                              <div className='button-container'>
                                <Button
                                  type="primary"
                                  text="Redimir premio"
                                  loading={false}
                                  status="active"
                                  link="/mis-redenciones"
                                  winner1={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                    {context && context.user[`idmask`] === 'A55555'
                    ? <div className='despegue-container type-2'>
                        <div className='img-container'>
                          <img src={`/assets/images/trivia.png`} alt={'trivia img'} />
                          <div className='title-container'>
                            <p className='misionName'>
                              Misión: Trivias
                            </p>
                            <p className='points'>
                              Gane 10 puntos
                            </p>
                            <p className='info'>
                              por responder correctamente.
                            </p>
                            <p className='desc'>
                              ¡Descubra preguntas nuevas cada semana!
                            </p>
                            <div className='pista'>
                              <img src={`/assets/images/pista.png`} alt={'candado img'} />
                              <span>
                                <strong>Pista:</strong> encuentre las respuestas en los correos de Misión Debitazo. 
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='extra-space'>
                          <div className={`trivias-container`}>
                            <div className='trivias-title-container'>
                              <div className='title-container'>
                                <p className='info'>
                                  Trivia 1
                                </p>
                                <p className='points'>
                                  Gane 10 puntos
                                </p>
                              </div>
                              <p className='available'>
                                {!submitted ? 'Respuesta pendiente' : 'Completa'}
                              </p>
                            </div>
                            <div className='trivias-body-container'>
                              <div className='trivias-body-question'>
                                <p>
                                  ¿Qué comisión le cobra Davivienda por usar su Tarjeta Débito en establecimientos comerciales?
                                </p>
                              </div>
                              <hr/>
                              <form onSubmit={handleSubmit}>
                                {["No tiene costo", "2%", "5%", "10%"].map((option, index) => (
                                  <label key={index} className="opcion">
                                    <input
                                      type="radio"
                                      name="commission"
                                      value={option}
                                      checked={selectedOption === option}
                                      onChange={handleChange}
                                      disabled={submitted}
                                    />
                                    <span></span>
                                    {option}
                                  </label>
                                ))}
                                {submitted && selectedOption === 'No tiene costo'
                                ? <div className='respuesta-correcta'>
                                    ¡Respuesta correcta!<br/>Ganó: 10 puntos
                                  </div>
                                : submitted && selectedOption !== 'No tiene costo' && 
                                  <>
                                    <div className='respuesta-incorrecta'>
                                      Respuesta incorrecta
                                    </div>
                                    <div className='respuesta-incorrecta-message'>
                                      La respuesta correcta es<br/><strong>No tiene costo.</strong>
                                    </div>
                                    <div className='tip'>
                                      <strong>Tip:</strong> En los correos que le enviamos semanalmente están las respuestas que necesita.
                                    </div>
                                  </>
                                }
                                <Button
                                  type="primary"
                                  text="Enviar respuesta"
                                  loading={false}
                                  status={!submitted ? "active" : "inactive"}
                                  datalayer={{event: 'trivia', text_title: 'Trivia 1', message: selectedOption === 'No tiene costo' ? 'Respuesta correcta' : 'Respuesta incorrecta', text: 'Enviar respuesta'}}
                                />
                                {/* <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                                  Enviar respuesta
                                </button> */}
                              </form>
                            </div>
                          </div>
                          <div className={`wallets-container type-2`}>
                            <div className='trivias-title-container'>
                              <p className='available'>
                                Disponible el 6 de mayo
                              </p>
                            </div>
                            <div className='img-container type-5'>
                              <div className='title-container type-3'>
                                <p className='points type-2'>
                                  Trivia 2
                                </p>
                                <p className='info type-2'>
                                  Gane 10 puntos
                                </p>
                              </div>
                              <img src={`/assets/images/candado.png`} alt={'candado img'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    : <div className='despegue-container'>
                        <div className='img-container'>
                          <img src={`/assets/images/trivia.png`} alt={'trivia img'} />
                          <div className='title-container'>
                            <p className='misionName'>
                              Misión: Trivias
                            </p>
                            <p className='points'>
                              Gane 10 puntos
                            </p>
                            <p className='info'>
                              por responder correctamente.
                            </p>
                            <p className='desc'>
                              ¡Descubra preguntas nuevas cada semana!
                            </p>
                            <div className='pista'>
                              <img src={`/assets/images/pista.png`} alt={'candado img'} />
                              <span>
                                <strong>Pista:</strong> encuentre las respuestas en los correos de Misión Debitazo. 
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={`wallets-container type-2`}>
                          <div className='trivias-title-container'>
                            <p className='available'>
                              Disponible el 6 de mayo
                            </p>
                          </div>
                          <div className='img-container type-5'>
                            <div className='title-container type-3'>
                              <p className='points type-2'>
                                Trivia 1
                              </p>
                              <p className='info type-2'>
                                Gane 10 puntos
                              </p>
                            </div>
                            <img src={`/assets/images/candado.png`} alt={'candado img'} />
                          </div>
                        </div>
                      </div>
                    }
                </div>
              }
            </div>
          </div>
        </div>
      : <Preloader
          type="section"
          invert
        />
      }
      { context && context.quiz && (context.quiz.length === 0 || context.quiz.every(q => q.response_1 === null)) &&
        <div className='mc-section-testForm'>
          <TestQuestions context={context} quizNumber={1} />
        </div>
      }
     
      {showModal && (
        <WalletModal
          show={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showWinnerModal && (
        <CustomModal
          title={`<strong>Está en el Top 5</strong> 🏆<br/> Quedó en la posición <strong><${context && context.tracing && context.tracing[`position`] ?  context.tracing[`position`] : ''}></strong> con <strong><${context && context.tracing && context.tracing[`points`] ?  context.tracing[`points`] : ''}></strong> puntos.`}
          desc={
            "¡Su premio está asegurado!<br/>Pronto nos contactáremos para que pueda reclamar su premio"
          }
          image="modal-ganador.png"
          show={showWinnerModal}
          setShowModal={setShowWinnerModal}
          noButton={true}
          status
      />
      )}
    </div>
  )
}
export default Progress