import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation 
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import { sendDataLayer } from '../../utils/functions'

function Header({
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction
}) {
  const { context } = useContext(StateController)
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [winnerMisionInsc, setWinnerMisionInsc] = useState(false)

  const isSubLevel = (children) => {
    const page = location.pathname.replace('/','')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e, page) => {
    setShowOthers(!showOthers)
    sendDataLayer({datalayer: {event: "menu",text: (e.target.textContent === '') ? 'Mas': e.target.textContent, link: page},repeat: true})

  }

  // const toogleSumMenu = (e) => {
  //   setShowMenu(!showMenu)
  //   sendDataLayer({datalayer: {event: "menu",text: e.target.textContent,link: window.location.href},repeat: true})

  // }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e, page) => {
    sendDataLayer({datalayer: {event: "menu",text: (e.target.textContent === '') ? 'Premios': e.target.textContent, link: page},repeat: true})

  }

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  useEffect(() => {
    let formattedDate;
    if(context) {
      const [day, month, year] = context.firstLoginDate.split("/");
      formattedDate = new Date(`${year}-${month}-${day}`);
    }
    if (context && new Date(formattedDate) < new Date("2025-04-11")) {
      setWinnerMisionInsc(true)
    }
  }, [context])

  const closeSession = (e) => {
    logOutAction()
    setShowOthers(false)
    sendDataLayer({datalayer: {event: "menu",text: e.target.textContent,link: window.location.href},repeat: true})

  }

  return (
    <div 
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${location.pathname.replace('/','') !== '' ? location.pathname.replace('/','') ? location.pathname.replace('/','') : 'login' : 'login' }
      ${showMenu ? 'show-menu' : ''}`
    }>
      <div className='mc-header-container__menu'>

        <a className={`mc-header-container__logo--desktop ${loggedIn && 'log'}`} href={loggedIn ? '/mecanica' : '/'}>
          <img src={`/assets/images/${logo}`} alt="Logo Banco Davivienda" />
        </a>
        {context &&
          <div className="mc-header-container__summary">
            <VerticalCenter>
              <div className='mc-header-container__summary--item amount'> Tiene {context.tracing ? (context.tracing[`points`]).toLocaleString("es-CO") : (!context.tracing && winnerMisionInsc) ?  25 : 0} puntos</div>
              {/* <Link to={`/progreso`}>Ver más</Link> */}
              <div className='tooTip-progress'>Su puntaje</div>
            </VerticalCenter>
          </div>
        }
        <Container fullHeight={true}>
            
          <a className={`mc-header-container__logo ${loggedIn && 'logged'}`} href="/">
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco Davivienda" className={`${loggedIn && 'log'}`}/>
            </VerticalCenter>
          </a>

          <nav className="mc-header-container__nav">
            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay" 
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul>
                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return true
                    } else {
                      return false
                    }
                  }).map((item, index) => {
                    return (
                      item.sublevel
                        ? <li 
                            className={`mc-header-container__nav--item slow_ani ${item.page} ${ ((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                            onClick={(e) => togglerMenu(e,item?.page)}
                            key={index}
                          >
                            <div className="mc-header-container__nav--button">
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title} 
                                </span>
                              </VerticalCenter>
                            </div>
                          </li>
                        : <li 
                            className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''} ${item.hide ? 'hide' : ''}`} 
                            onClick={(e) => addDataLayer(e,item.page)}
                            key={index}
                          >
                            <Link to={`/${item.page}`}>
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title}
                                </span>
                              </VerticalCenter>
                            </Link>
                          </li>
                    )
                  })}
                </ul>
              : <ul>

                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return false
                    } else {
                      return true
                    }
                  }).map((item, index) => {
                    return (
                      <li 
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                        onClick={addDataLayer}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={(e) => togglerMenu(e,item?.page)} className={`${item.hide ? 'hide' : ''}`}>
                      <span>
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                        {item.title}
                      </span>
                    </Link>
                  )
                })}
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  <span>
                    <Icon name='close_session' />
                    Cerrar sesión
                  </span>
                </div>
              </div>
            }
          </nav>
        </Container>
      </div>

      {submenu &&
        <div className={`mc-header-container__submenu ${!loggedIn ? 'not-login' : ''}`}>
          <Container fullHeight={true}>
            <VerticalCenter>
              {submenu.type === 'labels' &&
                <>
                  {submenu.icon && <Icon name={submenu.icon} />}
                  <div dangerouslySetInnerHTML={{ __html: submenu.content }} />
                </>
              }
              {submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                <Profile
                  nickname={context.user.nickname}
                  defaultLetter={submenu.default}
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header