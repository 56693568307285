import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AlliancesGallery from '../organisms/AlliancesGallery'
import Preloader from '../atoms/Preloader'
import Button from '../atoms/Button'

function Alliances() {
  const [alliances, setAlliances] = useState(false)
  const {context} = useContext(StateController)

  const asistances = [
    {
      image: '/assets/images/global-service.png',
      title: 'Mastercard Global Service',
      text: 'Asistencia en todo el mundo 24/7 para reportar tarjetas perdidas o robadas, brindar información de beneficios, servicios de emergencia o hacer reclamos.<br/><br/>Llama al 01-800-012-1274 (Colombia)<br/>+1-636-722-8822 (Extranjero)',
      cards: [
        'mc-standar.png'
      ]
    },
    {
      image: '/assets/images/proteccion.png',
      title: 'Protección de compras con cobertura de envío',
      text: 'Reembolso por robo y/o daño accidental de compras hechas con Mastercard.<br/><br/>Cobertura Débito Standard:<br/>Cobertura de 45 días y hasta 100 USD por evento / hasta 100 USD por evento / hasta 100 USD por 12 meses.',
      cards: [
        'mc-standar.png'
      ]
    },
    {
      image: '/assets/images/priceless.png',
      title: 'Priceless.com',
      text: 'Plataforma de  +2500 experiencias gratuitas y pagas como eventos, contenidos digitales y beneficios especiales, disponibles 24/7.<br/><br/>Utilizar este beneficio en 4 pasos:<ol> <li>Ingresa a priceless.com</li><li>Regístrate con tu tarjeta Mastercard</li><li>Accede a la plataforma</li><li>Disfruta las experiencias disponibles</li></ol>',
      cards: [
        'mc-standar.png'
      ]
    },
  ]

  useEffect(() => {
    if (context && context.alliances) {

      const formater = context.alliances.filter((item) => {
        if (item.activate) {
          if (item.activate.includes('/')) {
            const timer = new Date(item.activate)
            const today = new Date()
            if (today.toDateString() == timer.toDateString()) return true
            
          } else if (item.activate !== '') {
            const today = new Date()
            const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
            const todayDay = days[today.getDay()]
            const enableDays = item.activate.split(',')
            let returner = false

            for (let i = 0; i < enableDays.length; i++) {
              if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
            }
            return returner
          }
        } else {
          return true
        }
      }).map((item) => {
        
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule}`,
          image: item.image,
          termsData: item.terms
        }
      })
      
      setAlliances(formater)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page alliances slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Beneficios de su Tarjeta Débito Mastercard</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Use todas sus ventajas y supere las misiones en menos tiempo.</span>
      </div>
      <Container>
        {alliances
          ? <AlliancesGallery
              alliedBoxType={1}
              itemsPerLine={3}
              limit={false}
              termsOnModal={true}
              limitButtonText="Ver todos los aliados"
              list={alliances}
              termText="Aplican términos y condiciones."
              termLink="/terminos-y-condiciones"
            />
          : <Preloader
              type="section"
              invert
            />
        }
        <div className='assistance-container'>
          <h2>Asistencias Davivienda Mastercard</h2>
          <div className='mc-assistants'>
            {asistances.map((card, index) => {
              return (
                <div className='mc-assistants--item' key={index}>
                  <div className='mc-assistants--item__header'>
                    <div className='mc-assistants--item__header--title'>
                    <img src={card.image} alt={card.image} />
                      {card.title}
                    </div>
                  </div>
                  <div className='mc-assistants--item__body'>
                    <div dangerouslySetInnerHTML={{ __html: card.text}} />
                  </div>
                  <hr/>
                  <div className='mc-assistants--item__footer'>
                    <h3>Tarjetas que incluyen el servicio:</h3>
                    <div className={`mc-assistants--item__footer--include qty-${card.cards.length}`}>
                      {card.cards.map((cardUrl, index) => {
                        return (
                          <img key={index} src={`/assets/images/${cardUrl}`} />
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='SectionAssistanceServices'>
            <p>Para conocer los detalles sujetos a cada beneficio, consulte los términos y condiciones.</p>
            <div className='btn-allies-assistance'>
              <Button
                type='primary'
                text='Conozca más'
                link={'/tarjeton-interactivo.pdf'}
                status={'active'}
                external
                pdf2={true}
                datalayer={{event: 'alliances', text: 'Conozca más', link: '' }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Alliances