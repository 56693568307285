import React, { useState } from 'react';
import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import Button from '../atoms/Button'
import Slider from 'react-slick';
import useWidth from '../../hooks/useWidth'
import Icon from '../atoms/Icon';
import Preloader from '../atoms/Preloader';
import { sendDataLayer } from "../../utils/functions";

function Login({
  autologin,
  hash
}) {

  const { width } = useWidth();
  const [tabIndex, setTabIndex] = useState(0);
  const [extraLoginPreloader, setExtraLoginPreloader] = useState(false)

  const tabs = [
    { 
      label: 'Entrada', 
      image: '/assets/images/login.svg',
    },
    { 
      label: '¿Cómo participar?', 
      image: '/assets/images/participar.svg',
    }
  ]

  const sliderStepsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: width > 1344  ? 2 : width > 922 && width < 1344 ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    variableWidth: true
  }

  const sliderRewardsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: width > 922 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    variableWidth: true
  }

  const slidesSteps = [
    { 
      title: '1. Desbloquee las misiones', 
      image: 'Step_1',
      desc: 'Inscríbase, <strong>complete la primera misión</strong> con su Tarjeta Débito Davivienda Mastercard y <strong>sume sus primeros 1.000 puntos.</strong>',
    },
    { 
      title: '2. Siga sumando', 
      image: 'Step_2',
      desc: 'Superada la primera misión, se activa la segunda.<strong> Cada vez que la cumpla, ganará 100 puntos</strong> para seguir acumulando.',
    },
    { 
      title: '3. Tabla de posiciones', 
      image: 'Step_3',
      desc: '<strong>Los 1.500 participantes con más puntos</strong> al final de la campaña, ganarán. ¡No se pierda las misiones secretas que lo ayudarán a escalar posiciones más rápido!',
    },
  ]

  const slidesRewards = [
    {  
      image: '/assets/images/rewards_1.png',
      desc: '<strong>5 participantes ganarán</strong> experiencias para dos personas hasta por $15.000.000.',
    },
    {  
      image: '/assets/images/rewards_2.png',
      desc: '<strong>30 participantes ganarán</strong> bonos de hasta $7.000.000 en Jumbo, Falabella o Ktronix.',
    },
    {  
      image: '/assets/images/rewards_3.png',
      desc: '<strong>1.465 participantes</strong> podrán llevarse bonos de hasta $500.000 en los comercios disponibles.',
    },
  ]

  const handleTabClick = (idx, tab) => {
    sendDataLayer({datalayer: {event: 'access_options', text: tab.label }, repeat: true})
    setTabIndex(idx);
  }

  const handleClick = () => {
    setTabIndex(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className='mc-page login slow_ani'>

      <div className='mc-login__container'>
          <div className='tabs-container'>
            <section className="section-tab-login">
              <article>
                <div className="tab items">
                  {tabs.map((tab, idx) => (
                    <div
                      key={tab.label}
                      className={`tab-item ${tabIndex === idx ? 'active' : ''}`}
                      onClick={() => handleTabClick(idx, tab)}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') handleTabClick(tab.key);
                      }}
                      aria-selected={tabIndex === idx}
                    >
                      <h3>
                        <img src={tab.image} alt={`${tab.label} icon`} /> {tab.label}
                      </h3>
                    </div>
                  ))}
                </div>
              </article>
            </section>
          </div>
          {tabIndex === 0 &&
            <div>
              <Container>
                <div className='login-form-container'>
                  {width > 992
                  ? <img src={`/assets/images/debitazo.png`} />
                  : <img src={`/assets/images/debitazo-mobile.png`} />
                  }
                  <LoginForm
                    title="Inscríbase <strong>antes del 11 de abril</strong> y gane sus  primeros 25 puntos."
                    type="credentials"
                    fields={[
                      { field: "document", label: "Ingrese los últimos 5 dígitos de su documento", placeholder: "12345", maxlength: 5, separated: false },
                      { field: "birthdate", label: "Fecha de nacimiento", placeholder: "dd/mm/aaaa" },
                      { field: "terms", label: "He leído y acepto los", termsText: "términos y condiciones.", termsLink: "/terminos-y-condiciones" }
                    ]}
                    buttonText="Ingresar"
                    background="#fff"
                    autologin={autologin}
                    hash={hash}
                    extraLoginPreloader={extraLoginPreloader}
                    setExtraLoginPreloader={setExtraLoginPreloader}
                  />
                </div>
              </Container>
              <div className='login-info-card-container'>
                <Container>
                  <div className='login-info-card'>
                    <div className='login-info-card-text-container'>
                      <span className="login-info-card-text-bold">
                        ¿No tiene Tarjeta Débito Davivienda Mastercard?
                      </span>
                      <br/>
                      <span className="login-info-card-text">
                        ¡Solicítela y desbloquee nuevas posibilidades!
                      </span>
                    </div>
                    <div className='login-info-card-image'>
                      <img src={`/assets/images/Tarjeta.png`} />
                      <Button
                        type="primary"
                        text="Solicitar tarjeta" 
                        loading={false}
                        status="active"
                        link={'https://www.appdavivienda.com/productos/tarjetas/tarjeta-debito-digital/'}
                        external
                        datalayer={{event: 'request_card', text: 'Solicitar tarjeta', link: 'https://www.appdavivienda.com/productos/tarjetas/tarjeta-debito-digital/' }}
                      />
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          }
          {tabIndex === 1 &&
            <div className='participar-container'>
              <div className='participar-explanation'>
                {width > 992
                ? <img src={`/assets/images/debitazo.png`} />
                : <img src={`/assets/images/debitazo-mobile.png`} />
                }
                <div className='participar-steps-container'>
                  <h4>
                    ¿Qué debe hacer?
                  </h4>
                  <p>
                    Complete las misiones, gane puntos y participe por los premios.
                  </p>
                  <Slider {...sliderStepsSettings}>
                    {slidesSteps.map((step, index) => {   
                      return (
                        <div key={index} className={`participar-steps__items--item`}>
                          <div className='participar-steps__items--item--title'>
                            <svg className='svg-type2' xmlns="http://www.w3.org/2000/svg" width="47" height="58" viewBox="0 0 47 58" fill="none">
                              <path d="M-42.0209 27.5242C-46.8424 20.3517 -42.4501 14.599 -37.6689 12.6663C-22.6055 6.61382 25.3896 -7.81215 39.4468 6.18844C54.0262 19.5765 41.7613 69.3932 36.1225 84.5949C32.8814 93.2953 25.4791 90.4398 20.0217 87.1478C0.0141109 74.9629 -23.2747 55.3209 -42.0209 27.5242Z" fill="#FFC5C8"/>
                            </svg>
                            <h6>
                              <Icon name={step.image}/> {step.title}
                            </h6>
                          </div>
                          <div className='participar-steps__items--item--desc'>
                            <p dangerouslySetInnerHTML={{ __html: step.desc}} />
                          </div>
                        </div>
                      )  
                    })}
                  </Slider>
                </div>
              </div>
              <div className='participar-rewards'>
                <Container>
                  <div className= 'participar-rewards-container'>
                    <div className='participar-rewards-title'>
                      <svg className='svg-type2' xmlns="http://www.w3.org/2000/svg" width="47" height="58" viewBox="0 0 47 58" fill="none">
                        <path d="M-42.0209 27.5242C-46.8424 20.3517 -42.4501 14.599 -37.6689 12.6663C-22.6055 6.61382 25.3896 -7.81215 39.4468 6.18844C54.0262 19.5765 41.7613 69.3932 36.1225 84.5949C32.8814 93.2953 25.4791 90.4398 20.0217 87.1478C0.0141109 74.9629 -23.2747 55.3209 -42.0209 27.5242Z" fill="#FFC5C8"/>
                      </svg>
                      <h4>
                        <Icon name="login_rewards"/> Premios por posición
                      </h4>
                      <span>
                        Consulte qué premios recibirá según su ubicación en la tabla de posiciones al final de Misión Debitazo.
                      </span>
                    </div>
                    <div className='participar-rewards-desc'>
                      <Slider {...sliderRewardsSettings}>
                        {slidesRewards.map((step, index) => {   
                          return (
                            <div key={index} className={`participar-rewards__items--item`}>
                              <div className='participar-rewards__items--item--img'>
                                <img src={step.image} alt={step.image} />
                              </div>
                              <div className='participar-rewards__items--item--desc'>
                                <span dangerouslySetInnerHTML={{ __html: step.desc}} />
                              </div>
                            </div>
                          )  
                        })}
                      </Slider>
                    </div>
                  </div>
                </Container>
              </div>
              <div className='participar-button-container'>
                <Button
                  type="secondary"
                  text="Inscríbase en la Misión Debitazo"
                  status="active"
                  onClick={handleClick}
                  datalayer={{ event: 'accept_mission_debitazo'}}
                />
              </div>
            </div>
          }
      </div>
      {(extraLoginPreloader || autologin) &&
        <Preloader
          type="fullscreen"
          invert={true}
        />
      }
    </div>
  )
}
export default Login