import React, { useContext, useEffect, useState } from 'react'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import { StateController } from './../../App'
import { getMaximumBlock } from '../../utils/functions'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import AwardBox from '../molecules/AwardBox'

function Redemptions() {
  const {context} = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)

  useEffect(() => {
    if (context && context.redemtions) {
      console.log("Context redemtions:", context)
      setRedemtions(context.redemtions)
    }
  }, [context])

  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
      setRedemtions(
        context.redemtions
      )
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page redemptions slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Redenciones</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">¡Misión cumplida! Ahora elija su premio y redima su bono favorito.</span>
        <p className="mc-page__subtitle-date">Redima sus bonos antes de 3/06/2025</p>
      </div>
      <div className="sectionAwards">
        <Container>
        {awards && canRedem
          ? <AwardsGallery
              awardBoxType={3}
              itemsPerLine={3}
              limit={false}
              limitButtonText="Ver más comercios"
              showStock={false}
              list={awards}
              steps={false}
              canRedem={canRedem}
              twoStepAuth={false}
              redemtionText="Redima su premio"
              termText="Aplican términos y condiciones."
              termLink="/terminos-y-condiciones"
              termModal={true}
            />
          : awards && !canRedem
            ?<>
            </>
            : <Preloader
                type="section"
                invert
              />
        }
        </Container>
      </div>
      <Container>
        <div className={`sectionRedemptions lg-${redemtions?.length}`}>
        
          <div className='mc-page-content__redemptionsOnAwards--labels'>
            <h3>Bonos para descargar</h3>
            <p>Encuentre aquí los bonos que eligió y están listos para usar.</p>
            <p>Redima sus bonos antes de 3/06/2025</p>
          </div>
          <div className={`mc-page-content__redemptionsOnAwards--awards ${redemtions.length === 0 ? 'empty' : ''}`}>
            {redemtions.length === 0
              ? <VerticalCenter>
                  <img src="/assets/images/empty-redemtions.png" />
                </VerticalCenter>
              : <div className='mc-awards-container mc-awards-column-2'>
                  <div className="mc-awards-container__items download">
                    {redemtions[0] && 
                      <div className={`mc-awards-container__items--item type-${3}`}>
                        <AwardBox
                          type={3}
                          award={redemtions[0]}
                          canRedem={true}
                          redemtionText={'Descargar'}
                          download={true}
                          position={1}
                          isDownloaded={true}
                        />
                      </div>
                    }

                    {redemtions[1] && 
                      <div className={`mc-awards-container__items--item type-${3}`}>
                        <AwardBox
                          type={3}
                          award={redemtions[0]}
                          canRedem={true}
                          redemtionText={'Descargar'}
                          download={true}
                          position={1}
                          isDownloaded={true}
                        />
                      </div>
                    }
                  </div>
                </div> 
            }
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Redemptions