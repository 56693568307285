import React, { useEffect, useState, useContext } from 'react'

import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import Preloader from '../atoms/Preloader'

function Terms() {
  const { staticContent } = useContext(StateController)
  const [termsHTML, setTermsHTML] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent.terms && staticContent.terms[0].content) {
      setTermsHTML(staticContent.terms[0].content)
    }
  }, [staticContent])
  
  return (
    <div className='mc-page terms slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Términos y condiciones</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Conozca todos los detalles para completar su Misión Debitazo. ¡Compre, gane y redima!</span>
      </div>
      <Container>
        {termsHTML
          ? <div className='mc-static-content'>
              <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
            </div>
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>

    </div>
  )
}
export default Terms