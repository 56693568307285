import React from 'react'
import { useNavigate } from 'react-router-dom'
import { sendDataLayer } from "../../utils/functions";

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  external,
  pdf,
  datalayer,
  premioFinal,
  listado,
  winner1
}) {
  const navigate = useNavigate()
  const handler = () => {

    if(datalayer) {
      sendDataLayer({datalayer})
    }

    if(premioFinal) {
      sendDataLayer({datalayer: {event: 'ranking_center', text: 'Ir por mi premio', link: '/mis-redenciones' }, repeat: true})
    }

    if(listado) {
      sendDataLayer({datalayer: {event: 'ranking_center', text: 'Conoce el listado completo de ganadores', link: '' }, repeat: true})
    }

    if(winner1) {
      sendDataLayer({datalayer: {event: 'mission_center', text: 'Redimir premio', link: '/mis-redenciones' }, repeat: true})
    }

    if (status === 'active' && !loading) {
      if (pdf) {
        window.open(link, '_blank');
      } else {
        link
          ? external
            ? window.open(link, '_blank')
            : navigate(link)
          : onClick 
            ? onClick()
            : console.log("No se ejecuta nada")
      }
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status}`} disabled={status === 'inactive' ? true : false}>
        {loading
          ? <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
          : text
        }
      </button>
    </div>
  )
}

export default Button