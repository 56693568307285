import React, { useState } from 'react';
import Slider from 'react-slick';
import useWidth from '../../hooks/useWidth'
import Button from '../atoms/Button'
import ExperiencesModal from '../molecules/ExperiencesModal';
import Container from '../../layouts/Container/Container'
import { sendDataLayer } from "../../utils/functions";

function Awards() {
  const [tabIndex, setTabIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { width } = useWidth();

  const tabs = [
    { 
      label: 'Top 5',
    },
    { 
      label: 'Top 30',
    },
    {
      label: 'Top 1.465'
    }
  ]

  const sliderAwardsTop2 = [
    { 
      destination: 'Cartagena',
      country: 'Colombia',
      image: '/assets/images/cartagena.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Cartagena',
          desc:"<ul> <li>Transporte privado hasta el Hotel Santa Teresa.</li><li>Almuerzo en Harry's Sasson.</li><li>Tour privado por las murallas de la ciudad y Getsemaní.</li></ul>"
        },
        day2: {
          title: 'Día 2 - Islas del Rosario',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Makani Luxury Beach Club.</li><li>Experiencia de vino y cena en Erre de Ramón Freixa.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Día de spa',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Sesión de spa en pareja.</li><li>Transporte privado  hasta el aeropuerto.</li></ul>'
        }
      },
      value: 15
    },
    { 
      destination: 'Ciudad de México',
      country: 'México',
      image: '/assets/images/cdmx.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a México',
          desc:'<ul> <li>Transporte privado hasta el Hotel Marriott Reforma.</li><li>Bienvenida</li><li>Cena en el Restaurante Estoril Mastercard.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Clase de gastronomía',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Masterclass privada de comida mexicana.</li><li>Cena en el Restaurante italiano Quattro.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Servicio de transporte del hotel al aeropuerto.</li><li>Vuelo de regreso.</li></ul>'
        }
      },
      value: 15
    },
    { 
      destination: 'Flores',
      country: 'Guatemala',
      image: '/assets/images/flores.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Flores',
          desc:'<ul> <li>Viaje de Ciudad de Guatemala a Flores.</li><li>Servicio de transporte al Hotel Las Lagunas boutique.</li><li>Bienvenida</li><li>Tour a Tikal Mayan con almuerzo y bebidas incluidas.</li><li>Cena privada en el hotel.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Aventura en canopy',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Experiencia en canopy zipline.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Servicio de transporte del hotel al aeropuerto.</li></ul>'
        }
      },
      value: 15
    },
    { 
      destination: 'Ciudad de Panamá',
      country: 'Panamá',
      image: '/assets/images/panama.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Ciudad de Panamá',
          desc:'<ul> <li>Transporte privado hasta el Hotel Riu Plaza Panamá.</li><li>Bienvenida y recepción.</li><li>Tour privado por el Casco Antiguo de la ciudad.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Recorrido por el canal',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Tour exclusivo por el Canal de Panamá.</li><li>Tarde en el centro comercial Albrook.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Mañana de relajación.</li><li>Servicio de transporte del hotel al aeropuerto.</li></ul>'
        }
      },
      value: 15
    }
  ]

  const sliderAwardsTop5 = [
    { 
      destination: 'Cartagena',
      country: 'Colombia',
      image: '/assets/images/cartagena.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Cartagena',
          desc:'<ul> <li>Transporte privado hasta el Hotel Las Américas.</li><li>Almuerzo en Juan del Mar Restaurante.</li><li>Tour privado por las murallas de la ciudad y Getsemaní.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Islas del Rosario',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Makani Luxury Beach Club.</li><li>Experiencia de vino y cena en Erre de Ramón Freixa.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Día de spa',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Sesión de spa en pareja.</li><li>Transporte privado  hasta el aeropuerto.</li></ul>'
        }
      },
      value: 10
    },
    { 
      destination: 'Ciudad de México',
      country: 'México',
      image: '/assets/images/cdmx.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a México',
          desc:'<ul> <li>Transporte privado hasta el Hotel NH Collection Reforma.</li><li>Bienvenida</li><li>Cena en el Restaurante Estoril Mastercard.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Clase de gastronomía',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Masterclass privada de comida mexicana.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Servicio de transporte del hotel al aeropuerto.</li><li>Vuelo de regreso.</li></ul>'
        }
      },
      value: 10
    },
    { 
      destination: 'Flores',
      country: 'Guatemala', 
      image: '/assets/images/flores.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Flores',
          desc:'<ul> <li>Viaje de Ciudad de Guatemala a Flores.</li><li>Servicio de transporte al Hotel Isla de las Flores.</li><li>Bienvenida</li><li>Tour a Tikal Mayan con almuerzo y bebidas incluidas.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Aventura en canopy',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Experiencia en canopy zipline.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Desayuno el hotel.</li><li>Servicio de transporte del hotel al aeropuerto.</li></ul>'
        }
      },
      value: 10
    },
    { 
      destination: 'Ciudad de Panamá',
      country: 'Panamá',
      image: '/assets/images/panama.png',
      modal: {
        day1: {
          title: 'Día 1 - Llegada a Ciudad de Panamá',
          desc:'<ul> <li>Transporte privado hasta AC Hotel Panama City.</li><li>Bienvenida y recepción.</li><li>Tour privado por el Casco Antiguo de la ciudad.</li></ul>'
        },
        day2: {
          title: 'Día 2 - Recorrido por el canal',
          desc:'<ul> <li>Desayuno en el hotel.</li><li>Tour exclusivo por el Canal de Panamá.</li><li>Almuerzo en The Grill House.</li><li>Cena en el restaurante Intimo.</li></ul>'
        },
        day3: {
          title: 'Día 3 - Regreso',
          desc:'<ul> <li>Brunch en Mahalo.</li><li>Mañana de relajación.</li><li>Servicio de transporte del hotel al aeropuerto.</li></ul>'
        }
      },
      value: 10
    }
  ]

  const sliderAwardsTop5Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: width > 922 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    variableWidth: true
  }

  const awardsTop30 = [
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>5 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_3.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small> 6 al 10 de la tabla</small>
          </h6>
        </div>
      )
    },
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>10 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_4.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small> 11 al 20 de la tabla</small>
          </h6>
        </div>
      )
    },
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>15 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_5.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small> 21 al 35 de la tabla</small>
          </h6>
        </div>
      )
    }
  ];

  const awardsTop1400 = [
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>250 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_6.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small> 36 al 335 de la tabla</small>
          </h6>
        </div>
      )
    },
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>400 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_7.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small> 336 al 735 de la tabla</small>
          </h6>
        </div>
      )
    },
    {
      title: (
        <div className="awards-cards-title-container">
          <h6 className='type-2'>
            <strong>815 premios de</strong>
            <br/>
            <img src="/assets/images/experiencias_8.png" alt="login rewards img" />
            <br/>
            para las posiciones<br/><small>736 al 1535 de la tabla</small>
            <br/>
          </h6>
        </div>
      )
    }
  ];

  const sliderComerciosSettings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: width > 922 ? 7 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    variableWidth: true
  }

  const comercios = [
    `/assets/images/logo-crepes.png`,
    `/assets/images/logo-qbano.png`,
    `/assets/images/logo-pepeganga.png`,
    `/assets/images/logo-inkanta.png`,
    `/assets/images/logo-calzatodo.png`,
    `/assets/images/logo-totto.png`,
    `/assets/images/logo-velez.png`,
    `/assets/images/logo-tennis.png`,
    `/assets/images/logo-libreria-nacional.png`,
    `/assets/images/logo-decatlhon.png`,
    `/assets/images/logo-cromantic.png`,
    `/assets/images/logo-ktronix.png`,
    `/assets/images/logo-jumbo.png`,
    `/assets/images/logo-alkosto.png`,
  ]
  
  // useEffect(() => {
  //   if (context && context.awards) {
  //     setAwards(
  //       context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
  //     )
  //     setCanRedem(
  //       context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
  //     )
  //   }
  // }, [context])

  const handleTabClick = (idx, tab) => {
    sendDataLayer({datalayer: {event: 'access_options', text: tab.label }, repeat: true})
    setTabIndex(idx);
  }

  const handleShowModal = (step) => {
    sendDataLayer({datalayer: { event: 'rewards', text_title: step.destination, text: "Más detalles" },repeat: true})
    setModalData(step);
    setShowModal(true);
  };

  return (
    <div className='mc-page awards slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">Premios</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Descubra las premios que ganará si ocupa una posición dentro del top de los 1.500 ganadores.</span>
      </div>

      <div className='tabs-container'>
        <section className="section-tab-login">
          <article>
            <div className="tab items">
              {tabs.map((tab, idx) => (
                <div
                  key={tab.label}
                  className={`tab-item ${tabIndex === idx ? 'active' : ''}`}
                  onClick={() => handleTabClick(idx, tab)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') handleTabClick(tab.key);
                  }}
                  aria-selected={tabIndex === idx}
                >
                  <h3>
                    {tab.label}
                  </h3>
                </div>
              ))}
            </div>
          </article>
        </section>
      </div>
      <Container>
      {tabIndex === 0 &&
        <div className='awards-container'>
          <div className='awards-title-container'>
            <img src={`/assets/images/experiencias.png`} />
            <div className='awards-title'>
              <h5>Viajes</h5>
              <span>Sea el participante con la mayor cantidad de de puntos y gane un viaje todo incluido para dos personas.</span>
            </div>
          </div>
          <div className='awards-cards-container'>
            <div className='awards-cards-title-container'>
              <h6>
                <strong>2 viajes de</strong><img src={`/assets/images/experiencias_1.png`} alt={'login rewards img'} /> <div>para las posiciones <strong>1 y 2</strong></div>
              </h6>
            </div>
            <div className='awards-cards'>
              <h6>
                Podrá elegir entre:
              </h6>
              <Slider {...sliderAwardsTop5Settings}>
                {sliderAwardsTop2.map((step, index) => {   
                  return (
                    <div key={index} className={`awards-cards__items--item`}>
                      <div className='awards-cards__items--item--img'>
                        <img src={step.image} alt={step.image} />
                      </div>
                      <div className='awards-cards__items--item--desc'>
                        <div className='destination-container'>
                          <span className='destination'>
                            {step.destination}
                            <br/>
                            <span className='small'>
                              {step.country}
                            </span>
                          </span>
                        </div>
                        <span className='days'>
                          3 Días / 2 Noches 
                        </span>
                        <div className='button-container'>
                        <Button
                          type="link"
                          text="Más detalles" 
                          loading={false}
                          status="active"
                          onClick={() => handleShowModal(step)}
                        />
                        </div>
                      </div>
                    </div>
                  )  
                })}
              </Slider>
            </div>
          </div>
          <div className='awards-cards-container'>
            <div className='awards-cards-title-container'>
              <h6>
                <strong>3 viajes de</strong><img src={`/assets/images/experiencias_2.png`} alt={'login rewards 2 img'} /> <div>para las posiciones <strong>3 al 5</strong></div>
              </h6>
            </div>
            <div className='awards-cards'>
              <h6>
                Podrá elegir entre:
              </h6>
              <Slider {...sliderAwardsTop5Settings}>
                {sliderAwardsTop5.map((step, index) => {   
                  return (
                    <div key={index} className={`awards-cards__items--item`}>
                      <div className='awards-cards__items--item--img'>
                        <img src={step.image} alt={step.image} />
                      </div>
                      <div className='awards-cards__items--item--desc'>
                        <div className='destination-container'>
                          <span className='destination'>
                            {step.destination}
                            <br/>
                            <span className='small'>
                              {step.country}
                            </span>
                          </span>
                        </div>
                        <span className='days'>
                          3 Días / 2 Noches 
                        </span>
                        <div className='button-container'>
                        <Button
                          type="link"
                          text="Más detalles" 
                          loading={false}
                          status="active"
                          onClick={() => handleShowModal(step)}
                        />
                        </div>
                      </div>
                    </div>
                  )  
                })}
              </Slider>
            </div>
          </div>
        </div>
      }
      {tabIndex === 1 &&
        <div className='awards-container type-2'>
          <div className='awards-title-container'>
            <img src={`/assets/images/bonos.png`} />
            <div className='awards-title'>
              <h5>Bonos digitales</h5>
              <span>Conozca los bonos digitales que ganará de acuerdo con su lugar en la tabla de posiciones.</span>
            </div>
          </div>
          <div className='awards-cards-container type-2'>
            <div className='awards-bonos'>
              {awardsTop30.map((step, index) => {   
                return (
                  <div key={index} className={`awards-cards__items--item`}>
                    <div className='awards-cards__items--item--desc'>
                      <div>{step.title}</div>
                    </div>
                  </div>
                )  
              })}
            </div>
            <div className='comercios-container'>
              <span>Podrá redimir su bono en:</span>
              <div className='comercios-imgs'>
                <img src={`/assets/images/logo-ktronix.png`} />
                <img src={`/assets/images/logo-fallabela.png`} />
                <img src={`/assets/images/logo-jumbo.png`} />
              </div>
            </div>
          </div>
        </div>
      }
      {tabIndex === 2 &&
        <div className='awards-container type-2'>
          <div className='awards-title-container'>
            <img src={`/assets/images/bonos.png`} />
            <div className='awards-title'>
              <h5>Bonos digitales</h5>
              <span>Conozca los bonos digitales que ganará de acuerdo con su lugar en la tabla de posiciones.</span>
            </div>
          </div>
          <div className='awards-cards-container type-2'>
            <div className='awards-bonos'>
              {awardsTop1400.map((step, index) => {   
                return (
                  <div key={index} className={`awards-cards__items--item`}>
                    <div className='awards-cards__items--item--desc'>
                      <div>{step.title}</div>
                    </div>
                  </div>
                )  
              })}
            </div>
            <div className='comercios-container'>
              <span>Podrá redimir su bono en:</span>
                <div className='comercios-imgs type-2'>
                  <Slider {...sliderComerciosSettings}>
                    {comercios.map((comercio, index) => {   
                      return (
                        <img src={comercio} key={index}/>
                      )  
                    })}
                  </Slider>
                </div>
            </div>
          </div>
        </div>
      }
        {/* {awards 
          ? <AwardsGallery
              awardBoxType={1}
              itemsPerLine={3}
              limit={false}
              limitButtonText="Ver más comercios"
              showStock={false}
              list={awards}
              steps={false}
              canRedem={canRedem}
              twoStepAuth={false}
              redemtionText="Redimir bono"
              termText="Términos y condiciones"
              // termLink="/terminos-y-condiciones"
            />
          : <Preloader
              type="section"
              invert
            />
        } */}
      </Container>
      {showModal && modalData && (
        <ExperiencesModal
          modalInfo={modalData.modal}
          image={modalData.image}
          value={modalData.value}
          show={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  )
}
export default Awards