import React from "react";
import { Link } from "react-router-dom";

import Container from "../Container/Container";
import { sendDataLayer } from "../../utils/functions";

function Footer({
  footerType,
  footerCardsImg,
  secondaryFooterImg,
  links,
  bank,
  linksText,
  content,
  bankFooterImg,
  copyright,
}) {
  return (
    <div
      className={`mc-footer-container mc-footer-container-type-${footerType}`}
    >
      {footerType === 1 && (
        <div className="mc-footer-container__bg">
          <Container>
            <div className="mc-footer-container__section">
              {links && links[0] && (
                <ul className="mc-footer-container__section--links">
                  {links.map((link, index) => {
                    return (
                      <Link
                        onClick={(e) => {
                          sendDataLayer({
                            datalayer: {
                              event: "footer",
                              text: e.target.textContent,
                              link: link.link,
                            },
                            repeat: true,
                          });
                        }}
                        key={index}
                        to={link.link}
                      >
                        {link.text}
                      </Link>
                    );
                  })}
                </ul>
              )}
              <div className="links-text">
                {linksText && <p>{linksText}</p>}
                <p>Válido en Colombia</p>
              </div>
              <div className="mc-footer-container__text" dangerouslySetInnerHTML={{ __html: content }} />
              <div className="mc-footer-container__imgs">
                <img src="/assets/images/logo_davivienda.png"/>
                <img src="/assets/images/logo_mastercard.png"/>
                <img src="/assets/images/logo_fogafin.png"/>
              </div>
            </div>
            <hr/>
          </Container>
          <Container className="mc-footer-container__text">
            <div className="mc-footer-container__imgs">
              <img src="/assets/images/davivienda.png"/>
              <img src="/assets/images/copy.png"/>
            </div>
          </Container>
          <img src="/assets/images/vigilado.svg" className="vigilado"/>
        </div>
      )}

      {footerType === 2 && bankFooterImg && (
        <div className="mc-footer-container__bg">
          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className="mc-footer-container__bg--image"
            />
            <div className="mc-footer-container__sections">
              <div className="mc-footer-container__sections--section">
                <h4>Información:</h4>
                {links && links[0] && (
                  <ul className="mc-footer-container__section--links">
                    {links.map((link, index) => {
                      return (
                        <Link key={index} to={link.link}>
                          {link.text}
                        </Link>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="mc-footer-container__sections--section">
                <h4>Contacto:</h4>
                <p>xxxxxxxxxxxx</p>
              </div>
            </div>

            {secondaryFooterImg && (
              <img
                src={`/assets/images/${secondaryFooterImg}`}
                alt="Footer"
                className="mc-footer-container__image"
              />
            )}
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated"
              alt="Footer logos"
            />
          </Container>
        </div>
      )}

      {footerType === 3 && (
        <div className="mc-footer-container__bg">
          {footerCardsImg && (
            <img
              src={`/assets/images/${footerCardsImg}`}
              alt="Footer Cards"
              className="mc-footer-container__cards"
            />
          )}

          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className="mc-footer-container__bg--image"
            />
            <div className="mc-footer-container__section">
              {links && links[0] && (
                <ul className="mc-footer-container__section--links">
                  {links.map((link, index) => {
                    return (
                      <Link key={index} to={link.link}>
                        {link.text}
                      </Link>
                    );
                  })}
                </ul>
              )}
              {secondaryFooterImg && (
                <img
                  src={`/assets/images/${secondaryFooterImg}`}
                  alt="Footer"
                  className="mc-footer-container__image"
                />
              )}
            </div>
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated"
              alt="Footer logos"
            />
          </Container>
          <Container>
            <div className="mc-footer-container__subbg">{copyright}</div>
          </Container>
        </div>
      )}

      {footerType === 4 && (
        <div className="footer_container">
          <img
            src="/assets/images/vigilated.png"
            className="mc-footer-vigilated"
            alt="Footer logos"
          />
          <Container className="logos_container">
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className="mc-footer-container__bg--image"
            />
          </Container>

          <Container className="buttons_container">
            <div className="buttons">
              {links && links[0] && (
                <ul className="mc-footer-container__section--links">
                  {links.map((link, index) => {
                    return (
                      <Link key={index} to={link.link}>
                        {link.text}
                      </Link>
                    );
                  })}
                </ul>
              )}
            </div>
          </Container>

          <Container className="contacts_container">
            <div className="contacts">
              <div className="contact1">
                <img src="/assets/images/award.png" alt="" className="award" />
                <b className="title">
                  Contacto 1:<p className="content">XXXXXXXXXXXXXXXX</p>
                </b>
              </div>
              <div className="contact2">
                <img src="/assets/images/award.png" alt="" className="award" />
                <b className="title">
                  Contacto 2:<p className="content">XXXXXXXXXXXXXXXX</p>
                </b>
              </div>
            </div>
          </Container>

          <Container className="text_container">
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="text"
            />
          </Container>

          <Container className="icons">
            <img
              src={`/assets/images/${secondaryFooterImg}`}
              alt="Footer"
              className="mc-footer-container__image"
            />
          </Container>

          <Container className="closing_container">
            <div className="closing">{bank}</div>
          </Container>
        </div>
      )}

      {footerType === 5 && (
        <div className="mc-footer-container__bg">
          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className="mc-footer-container__bg--image"
            />
            <div className="mc-footer-container__sections">
              <div className="mc-footer-container__sections--section">
                <h4>Información:</h4>
                {links && links[0] && (
                  <ul className="mc-footer-container__section--links">
                    {links.map((link, index) => {
                      return (
                        <Link key={index} to={link.link}>
                          {link.text}
                        </Link>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="mc-footer-container__sections--section">
                <h4>Contacto:</h4>
                <p>Bogotá: (601) 444 1777</p>
                <p>Cali: (602) 885 9595</p>
                <p>Medellín: (604) 325 6000</p>
                <p>Línea nacional: 01 8000 518 000</p>
              </div>
            </div>

            {secondaryFooterImg && (
              <img
                src={`/assets/images/${secondaryFooterImg}`}
                alt="Footer"
                className="mc-footer-container__image"
              />
            )}
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated"
              alt="Footer logos"
            />
          </Container>
        </div>
      )}
    </div>
  );
}

export default Footer;
