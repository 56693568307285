import React, { useState } from 'react'
import Container from '../../layouts/Container/Container'
import Button from '../atoms/Button'

import {
  stQuiz
} from './../../utils/helpers'
import { connect } from 'react-redux'
import { sendDataLayer } from '../../utils/functions'

function TestQuestions({
  token,
  quizNumber
}) {
  const [yesOrNot, setYesOrNot] = useState(false)
  const [response, setResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ok, serOk] = useState(false)

  const respond = (str) => {
    setYesOrNot(str)
  }
  const handleQuiz = () => {
    sendDataLayer({datalayer: { event: 'survey', text_title: '¿Te pareció útil este contenido?', message: yesOrNot, text: "Enviar" },repeat: true})
    
    if (!ok) {
      setIsLoading(true)
  
      stQuiz(token, yesOrNot, response, quizNumber).then((res) => {
  
        if (res && res.statusCode === 200) {
          setYesOrNot(false)
          setResponse("")
          serOk(true)
        }
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    }

  }
  const handleChange = async (e) => {
    if (e.target.value.length <= 500) setResponse(e.target.value)
  }

  return (
    <div className={`mc-test-questions`}>
      <Container>
        <h4>
          {ok
            ? '¡Gracias por tus comentarios!'
            : '¿Te pareció útil este contenido?'
          }
        </h4>
        {!ok &&
          <div className='mc-test-questions--buttons'>
            <button className={`button-quiz ${yesOrNot === 'Si' ? 'active': ''}`} onClick={() =>respond('Si')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <g clipPath="url(#clip0_10456_8578)">
              <path d="M5.16732 14.7663H3.16732C2.8137 14.7663 2.47456 14.6258 2.22451 14.3758C1.97446 14.1257 1.83398 13.7866 1.83398 13.433V8.76632C1.83398 8.41269 1.97446 8.07356 2.22451 7.82351C2.47456 7.57346 2.8137 7.43298 3.16732 7.43298H5.16732M9.83398 6.09965V3.43298C9.83398 2.90255 9.62327 2.39384 9.2482 2.01877C8.87313 1.6437 8.36442 1.43298 7.83398 1.43298L5.16732 7.43298V14.7663H12.6873C13.0089 14.77 13.3209 14.6573 13.5659 14.449C13.8109 14.2407 13.9724 13.9509 14.0207 13.633L14.9407 7.63298C14.9697 7.44189 14.9568 7.24677 14.9029 7.06115C14.849 6.87553 14.7554 6.70385 14.6286 6.55799C14.5017 6.41214 14.3447 6.2956 14.1684 6.21646C13.992 6.13732 13.8006 6.09746 13.6073 6.09965H9.83398Z" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_10456_8578">
              <rect width="16" height="16" fill="white" transform="translate(0.5 0.0996094)"/>
              </clipPath>
              </defs>
            </svg>
              Sí
            </button>
            <button className={`button-quiz ${yesOrNot === 'No' ? 'active': ''}`} onClick={() =>respond('No')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <g clipPath="url(#clip0_10456_8512)">
              <path d="M11.3334 1.43298H13.1134C13.4907 1.42631 13.8573 1.5584 14.1436 1.80419C14.43 2.04998 14.6161 2.39235 14.6667 2.76631V7.43298C14.6161 7.80694 14.43 8.14931 14.1436 8.3951C13.8573 8.64089 13.4907 8.77299 13.1134 8.76631H11.3334M6.66669 10.0996V12.7663C6.66669 13.2967 6.8774 13.8055 7.25247 14.1805C7.62755 14.5556 8.13625 14.7663 8.66669 14.7663L11.3334 8.76631V1.43298H3.81335C3.4918 1.42935 3.17977 1.54204 2.93475 1.75031C2.68973 1.95858 2.52824 2.24838 2.48002 2.56631L1.56002 8.56631C1.53102 8.75741 1.5439 8.95253 1.59779 9.13815C1.65168 9.32377 1.74529 9.49545 1.87211 9.6413C1.99894 9.78716 2.15597 9.90369 2.33231 9.98283C2.50864 10.062 2.70008 10.1018 2.89335 10.0996H6.66669Z" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_10456_8512">
              <rect width="16" height="16" fill="white" transform="translate(0 0.0996094)"/>
              </clipPath>
              </defs>
            </svg>
              No
            </button>
          </div>
        }

        {!ok &&
          <div className={`mc-test-questions--details slow_ani  active ${isLoading ? 'loading' : ''}`}>
            <p>
              {
                yesOrNot === 'Si'
                  ? 'Cuéntanos más detalles'
                  : 'Cuéntanos más detalles'
              }
            </p>
            <textarea className={!yesOrNot ? 'disabled' : 'true'} disabled={yesOrNot ? false : true} onChange={handleChange} value={`${response ? response : ''}`} placeholder='Escribe aquí tus comentarios'></textarea>
            <span className='mc-test-questions--live'>{response ? response.length : 0} de 500 caractéres</span>
            <Button
              type="primary"
              text="Enviar"
              loading={isLoading}
              status={yesOrNot === 'Si' ? 'active' : yesOrNot === 'No' ? 'active' : 'inactive'}
              onClick={handleQuiz}
            />
          </div>
        }
      </Container>
    </div>
  )
}

const mapState = ({ user: {token} }) => {
  return {
    token
  }
}

export default connect(mapState, {})(TestQuestions)