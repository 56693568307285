import React, { useEffect, useContext, useState } from 'react'
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import FormQuantumData from './FormQuantumData'
import { sendDataLayer } from '../../utils/functions'

function CustomModal({
  token,
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  cta,
  twoStepAuth,
  location,
  brand_id,
	quantumName,
	setQuantumName,
	quantumDoc,
	setQuantumDoc,
  department,
  city,
  site,
	setDepartment,
  setCity,
  setSite,
  redemtion,
  position,
  status,
  noButton
}) {
  const { setMdalOpen } = useContext(StateController)
  const [expDate, setExpDate] = useState('')

  const [errors, setErrors] = useState({
    expDate: false
  })

  useEffect(() => {

    setMdalOpen(show ? true : false)
    console.log('cta' + cta)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({datalayer: {event: 'cancel_bond', product_name: desc, product_price: title, product_type: "inteligentes", index: position},repeat: true})
    }
    setShowModal(false)
  }

  const handleChange = async (e) => {    
    const element = e.target.getAttribute('rel') 

    if (element === 'birthdate') {
      setExpDate(e.target.value)
      setErrors({ ...errors, expDate: false})

      // setTwoStepAuthStatus(true)
    }
  }

  // useEffect(() => {

  //   if (
  //     (twoStepAuth && twoStepAuthStatus) || 
  //     (location && location === 1 && quantumName !== '' && quantumDoc !== '') || 
  //     (location && location === 2 && site?.selected)
  //   ) {
  //     setPrimaryActionStatus('active')
  //   } else {
  //     setPrimaryActionStatus('inactive')
  //   }

  //   if (!twoStepAuth && location === 0) setPrimaryActionStatus('active')

  // }, [twoStepAuth, location, quantumName, quantumDoc, site])
  
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            <div className="mc-custom-modal__close" onClick={close}>
              <Icon name="close" />
            </div>
            {redemtion
              ? <div>
                  {image &&
                    <img  className="mc-custom-modal__center--window__imager" src={`/assets/images/${image}`} alt={title} />
                  }
                  <h3 className="mc-custom-modal__center--window__head">{desc}</h3>
                  <div className="mc-custom-modal__center--window__title">Bono por: <strong>{title}</strong></div>
                </div>
              : <div>
                  {image &&
                    <div
                      className={`mc-custom-modal__center--window__image ${status && 'status'}`}
                      style={{"backgroundImage": `url(/assets/images/${image})`}}
                    >
                    </div>
                  }
                  {status
                  ? <div className={`mc-custom-modal__center--window__title ${status && 'status'}`}>
                      <div dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                  : <div className={`mc-custom-modal__center--window__title ${status && 'status'}`}>{title}</div>
                  }
                  <div className={`mc-custom-modal__center--window__desc ${status && 'status'}`}>
                    <div dangerouslySetInnerHTML={{ __html: desc }} />
                  </div>
                </div>
            }

            {twoStepAuth &&
              <div className="mc-custom-modal__center--window__auth">
                <p>Doble autenticación</p>

                <Input 
                  type={'birthdate'}
                  label={'Fecha de expedición de su cédula'}
                  placeholder={'DD/MM/AAAA'}
                  onChange={handleChange}
                  inputByCharacter={false}
                  maxlength={10}
                  value={expDate}
                  error={errors.expDate}
                />
              </div>
            }

            {
              location ?
              <FormQuantumData
                token={token}
                location={location}
                brand_id={brand_id}
                quantumName={quantumName}
                setQuantumName={setQuantumName}
                quantumDoc={quantumDoc}
                setQuantumDoc={setQuantumDoc}
                department={department}
                city={city}
                site={site}
                setDepartment={setDepartment}
                setCity={setCity}
                setSite={setSite}
              />
              :
              <></>
            }

            <div className={`mc-custom-modal__center--window__actions ${redemtion ? 'redemtion' : ''}`}>
              {secondaryAction &&
                <Button
                  type="outline"
                  text={secondaryAction.buttonText}
                  loading={false}
                  status="active"
                  onClick={secondaryAction.action}
                />
              }
              {!noButton &&
                <Button
                type="primary"
                text={primaryAction ? primaryAction.buttonText : cta}
                loading={primaryAction ? primaryAction.loading : false}
                status="active"
                onClick={primaryAction ? primaryAction.action : close}
              />
              }
              
            </div>
            {terms &&
              <div className="mc-custom-modal__center--window__terms">
                <div className='custom-a'>
                  {terms.termsText}
                </div>
              </div>
            }

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal