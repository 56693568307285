import React from 'react'
import { Link } from "react-router-dom"
import AlliedBox from '../molecules/AlliedBox'

function AlliancesGallery({
  alliedBoxType,
  itemsPerLine,
  limit,
  termsOnModal,
  limitButtonText,
  list,
  termText,
  termLink
}) {

  return (
    <div className={`mc-alliances-container mc-alliances-column-${itemsPerLine}`}>
      
      {list &&
        <div>
          <div className="mc-alliances-container__title">
            <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">
              <path d="M-27.3491 37.4243C-33.2308 31.0921 -29.7899 24.7244 -25.3688 22.0694C-11.4344 13.7405 33.7217 -7.99811 49.7914 3.63743C66.2813 14.5864 61.9402 65.7068 58.7426 81.6022C56.8988 90.7018 49.1417 89.0363 43.2374 86.6362C21.5735 77.7226 -4.49494 61.9553 -27.3491 37.4243Z" fill="#FFC5C8"/>
            </svg>
            <h4>
              <img src={`/assets/images/beneficios.png`} alt={'beneficios img'} /> Comercios con beneficios por tener una Tarjeta Mastercad 
            </h4>
          </div>
          <div className="mc-alliances-container__items">
            {list.filter((item, i) => {

              return limit ? i < limit ? true : false : true
            }).map((item, index) => {

              return (
                <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType}`}>
                  <AlliedBox
                    type={alliedBoxType}
                    allied={item}
                    termLink={termLink}
                    termText={termText}
                    termsOnModal={termsOnModal}
                    termsData={item.termsData}
                  />
                </div>
              )
            })}
            <div className='SectionAssistanceServices'>
              <p>Banco Davivienda S.A. actúa únicamente como emisor de las tarjetas Davivienda Mastercard, informando a los tarjetahabientes del descuento. Consulte tasas y tarifas, reglamento, términos y condiciones del producto en www.davivienda.com.</p>
            </div>
          </div>
        </div>
      }

      {limit && limitButtonText &&
        <div className="mc-alliances-container__actions">
          <Link to="/alianzas">{limitButtonText}</Link>
        </div>
      }

    </div>
  )
}

export default AlliancesGallery