import React, { useEffect, useContext } from 'react'
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

function ExperiencesModal({
  show,
  setShowModal,
  image,
  modalInfo,
  value
}) {
  const { setMdalOpen } = useContext(StateController)
 
  useEffect(() => {
    setMdalOpen(show);
    
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  const close = () => {
    setShowModal(false)
  }
  
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            <div className="mc-custom-modal__close" onClick={close}>
              <img src={`/assets/images/close.svg`} className='close-img' alt='close img'/>
            </div>
            {image &&
              <div
                className="mc-custom-modal__center--window__image"
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.00) 68.28%, rgba(0, 0, 0, 0.40) 101.72%), 
                               url(${image}) lightgray -126.574px 0px / 175.359% 100% no-repeat`
                }}
              >
              {value === 10
              ? <img src={`/assets/images/exp-10.png`} className='img-exp'/>
              : <img src={`/assets/images/exp-15.png`} className='img-exp'/>
              }
              
              </div>
            }
            <div className="mc-custom-modal__center--window__desc">
              {modalInfo && Object.values(modalInfo).map((day, index) => (
                <div key={index}>
                  <h6>{day.title}</h6>
                  <div dangerouslySetInnerHTML={{ __html: day.desc }} />
                </div>
              ))}
            </div>
          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default ExperiencesModal