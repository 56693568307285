import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CustomModal from "./CustomModal";
import Button from "../atoms/Button";
import Tag from "../atoms/Tag";

import { getPrice, sendDataLayer } from "../../utils/functions";
import { doloadRedeem } from "../../utils/helpers";
import { StateController } from "./../../App";

function AwardBox({
  index,
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  showStock,
  token,
  download,
  position
}) {
  const { setAwardSelected, handlerRootModal } = useContext(StateController);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showErrorDownload, setShowErrorDownload] = useState(false);
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false);

  const handlerModal = () => {
    if (!download) {
      sendDataLayer({datalayer: {event: 'bond_interest', product_name: award.name, product_price: award.price, product_type: "inteligentes", index: position + 1},repeat: true})
    }

    if (download && !downloadLoading) {
      console.log("Redencion:", award);
      setDownloadLoading(true);

      doloadRedeem(award.id, token).then((data) => {
        console.log("result download:", data)
        setDownloadLoading(false)

        sendDataLayer({datalayer: {event: 'redownload', product_name: award.name, product_price: award.price, product_type: "inteligentes"},repeat: true})

        if (data && (data.status === 402 || data.status === 400)) {
          setShowErrorDownload(true)
        }
      }).catch((error) => {
          console.log("ERROR DOWNLOAD REDENTION:", error);
          setDownloadLoading(false);
          setShowErrorDownload(true);
        });
    } else {
      if (canRedem) {
        handlerRootModal();
        setAwardSelected({
          image: award.image,
          price: award.price,
          name: award.name,
          canRedem: canRedem,
          id: award.id,
          index: index + 1,
        });
      }
    }
  };

  console.log(award);
  return (
    <div className="mc-awards-container__items--item__container">
      <CustomModal
        title="Error en la descarga"
        desc={
          "Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde."
        }
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload}
        setShowModal={setShowErrorDownload}
      />
      <CustomModal
        title="Error en la redención"
        desc={
          "Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde."
        }
        cta="Aceptar"
        image="image.png"
        show={showErrorRedemtion}
        setShowModal={setShowErrorRedemtion}
      />

      {award.prepurchased && !download && <Tag text="Precompra" />}
      {showStock && (
        <Tag
          position={"left"}
          text={`${award.stock} Disponible${award.stock > 1 ? "s" : ""}`}
        />
      )}
      {type === 1 && (
        <>
          <div className="mc-awards-container__items--item__image">
            <img src={`/assets/images/${award.image}`} alt={award.name} />
          </div>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__name">
              {award.name}
            </div>
            <div className="mc-awards-container__items--item__price">
              COP {getPrice(award.price)}
            </div>
            <div className="mc-awards-container__items--item__description">
              {award.description && <p>{award.description}</p>}
            </div>
            {termLink && (
              <p>
                <Link to={termLink} target="_blank" rel="noreferrer" 
                  onClick={() => {
                    sendDataLayer({
                      datalayer: {
                        event: "bonus_terms",
                        text: termText
                      },
                      repeat: true,
                    });
                  }}>
                  {termText}
                </Link>
              </p>
            )}
            <Button
              type="primary"
              text={redemtionText}
              loading={downloadLoading}
              status={canRedem ? "active" : "inactive"}
              onClick={(e) => handlerModal(e, award)}
            />
          </div>
        </>
      )}
      {type === 2 && (
        <>
          <div className="mc-awards-container__items--item__info">
            <div
              className="mc-awards-container__items--item__image"
              style={{ backgroundImage: `url(/assets/images/${award.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__info2">
              <div className="mc-awards-container__items--item__name">
                {award.name}
              </div>
              <div className="mc-awards-container__items--item__price">
                COP {award.price}
              </div>
            </div>
          </div>
          {termLink && (
            <p>
              <a href={termLink} target="_blank" rel="noreferrer">
                {termText}
              </a>
            </p>
          )}
          <Button
            type="primary"
            text={redemtionText}
            loading={false}
            status="active"
            onClick={(e) => handlerModal(e)}
          />
        </>
      )}
      {type === 3 && (
        <>
          <div className={`mc-awards-container__items--item__info download`} >
            <div
              className="mc-awards-container__items--item__image"
              style={{ backgroundImage: `url(/assets/images/${award.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__labels">
              <div className="mc-awards-container__items--item__name">
                {award.name}
              </div>
              <div className="mc-awards-container__items--item__price">
                {getPrice(award.price)}
              </div>
            </div>
          </div>
          <div className="mc-awards-container__items--item__actions">
            {/* {termLink && (
              <p>
                <a href={termLink} target="_blank" rel="noreferrer"
                  onClick={() => {
                    sendDataLayer({
                      datalayer: {
                        event: "bonus_terms",
                        text: termText
                      },
                      repeat: true,
                    });
                  }}
                >
                  {termText}
                </a>
              </p>
            )} */}
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status={canRedem ? 'active' : 'inactive'}
              onClick={(e) => handlerModal(e,)}
              icon={download ? false : canRedem ? false : 'secure'}
            />
          </div>
        </>
      )}
      {type === 4 && (
        <>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__image">
              <span
                style={{
                  backgroundImage: `url(/assets/images/${award.image})`,
                }}
              ></span>
            </div>
            <div className="mc-awards-container__items--item__name">
              {award.name}
            </div>
            <div className="mc-awards-container__items--item__price">
              COP {award.price}
            </div>
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e)}
            />
            {termLink && (
              <p>
                <a href={termLink} target="_blank" rel="noreferrer">
                  {termText}
                </a>
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapState = ({ user: { token } }) => {
  return {
    token,
  };
};

export default connect(mapState)(AwardBox);
