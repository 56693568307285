import React from 'react'
import Container from '../../layouts/Container/Container'
import Icon from '../atoms/Icon'
import Button from '../atoms/Button'
function NotFound({loggedIn}) {

  return (
    <div className={`mc-page not-found slow_ani ${!loggedIn && 'log'}`}>
      <Container>
        <div className='not-found-container'>
          <div className='number-container'>
            <div className='title'>
              <h1>
                Error
                <Icon name="error" />
              </h1>
            </div>
            <span className='number'>
              404
            </span>
          </div>
          <div className='text-container'>
            <h2>
              ¡Misión no encontrada!
            </h2>
            <p>
              Parece que tomó el camino equivocado. Regrese sus Misiones y siga sumando puntos para ganar. 
            </p>
            <div className='button-container'>
              <Button
                type='primary'
                text='Ir a Misiones'
                link={'/progreso'}
                status={'active'}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default NotFound