import React, { useEffect, useContext, useState} from 'react'
import { StateController } from './../../App'
import { sendDataLayer } from "../../utils/functions";

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

function WalletModal({
  show,
  setShowModal
}) {
  const { setMdalOpen } = useContext(StateController)
  const tabs = [
    { 
      label: 'Apple Pay', 
      OS: 'IOS',
      desc:'<div class="instrucciones"> <ol> <li>Abra la app Wallet en su iPhone, toque el "+" y seleccione “Agregar tarjeta de débito o crédito”.</li><li>Escanee su tarjeta o ingrese los datos manualmente.</li><li>Siga las instrucciones para verificar su tarjeta y listo.</li></ol></div>'
    },
    { 
      label: 'Google Wallet', 
      OS: 'Android',
      desc:'<div class="instrucciones"> <ol> <li>Abra la app Wallet en su celular, toque el "Agregar a Wallet" y  seleccione "Tarjeta de pago".</li><li>Escanee su tarjeta o ingrese los datos manualmente.</li><li>Siga las instrucciones para verificar su tarjeta y listo.</li></ol></div>'
    }
  ]
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabClick = (idx, tab) => {
    sendDataLayer({datalayer: {event: 'mission_center', text: tab.label, link: '' }, repeat: true})
    setTabIndex(idx);
  }
 
  useEffect(() => {
    setMdalOpen(show);
    
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  const close = () => {
    setShowModal(false)
  }
  
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            <div className="mc-custom-modal__close" onClick={close}>
              <img src={`/assets/images/close.svg`} className='close-img' alt='close img'/>
            </div>
            <div className="mc-custom-modal__center--window__desc">
            <div>
              <div className={`wallets-container`}>
                <p className='title'>
                  Siga los pasos para registrar su tarjeta según su sistema operativo:
                </p>
                <div className='tabs-container'>
                  <section className="section-tab-login">
                    <article>
                      <div className="tab items">
                        {tabs.map((tab, idx) => (
                          <div
                            key={tab.label}
                            className={`tab-item ${tabIndex === idx ? 'active' : ''}`}
                            onClick={() => handleTabClick(idx, tab)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') handleTabClick(tab.key);
                            }}
                            aria-selected={tabIndex === idx}
                          >
                            <h3>
                              {tab.label}
                            </h3>
                            <span>
                              {tab.OS}
                            </span>
                          </div>
                        ))}
                      </div>
                    </article>
                  </section>
                </div>
                <div dangerouslySetInnerHTML={{ __html: tabs[tabIndex].desc}} />
              </div>
              <p className='note'>
                *Lorem ipsum celular no disponible
                para realizar la misión billetera digital
              </p>
            </div>
            </div>
          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default WalletModal