import React, { useEffect, useState } from "react";

const LoginProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 50); // 30ms * 100 = 3000ms (3 segundos)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="login-progress-container">
      <div className="login-progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default LoginProgressBar;