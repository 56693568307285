import { sendDataLayer } from '../../utils/functions'
import React, { useState } from 'react'
import CustomModal from './CustomModal'

function AlliedBox({
  type,
  allied,
  termLink,
  termText,
  termsOnModal,
  termsData
}) {
  const [showOnModal, setShowOnModal] = useState(false)
  const dataLayerAllied =(content)=>{
    sendDataLayer({datalayer: {event: "alliances",text: content?.name,link: window.location.href},repeat: true})  
  }
  
  return (
    <div className={`mc-alliances-container__items--item__container ${termsOnModal ? 'terms' : ''}`}>
      {type === 1 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <img src={`/assets/images/${allied.image}`} alt={allied.name+" image"}/>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
            <img src={`/assets/images/${allied.rule}`} alt="Descuento" className={`discount-image ${allied.name == 'Farmatodo' ? 'minimal' : ''}`} />
            <div className="mc-alliances-container__items--item__desc">
              <div dangerouslySetInnerHTML={{ __html: allied.description }} />
            </div>
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink} target='_blank' rel="noreferrer">
              {termText}
            </a>
          }
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            </div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink} target='_blank' rel="noreferrer">
              {termText}
            </a>
          }
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
              <div className="mc-alliances-container__items--item__desc">
                <div dangerouslySetInnerHTML={{ __html: allied.description }} />
              </div>
              {termLink &&
                <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              }
            </div>
          </div>
        </>
      }

      <CustomModal
        title="Términos y condiciones"
        desc={termsData}
        cta="Aceptar"
        icon={false}
        show={showOnModal} setShowModal={setShowOnModal}
        termsOnModal={true}
      />
    </div>
  )
}

export default AlliedBox