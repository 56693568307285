import React, { useContext } from 'react'
import { StateController } from './../../App'
import TestQuestions from '../organisms/TestQuestions'
import Container from '../../layouts/Container/Container'
import Slider from 'react-slick';
import useWidth from '../../hooks/useWidth'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon';

function Mechanic() {

  const { width } = useWidth();
  const { context } = useContext(StateController);

  const sliderRewardsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: width > 922 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    variableWidth: true
  }

  const slidesSteps = [
    { 
      title: '1. Desbloquee las misiones', 
      image: 'Step_1',
      desc: 'Inscríbase, <strong>complete la primera misión</strong> con su Tarjeta Débito Davivienda Mastercard y <strong>sume sus primeros 1.000 puntos.</strong>',
    },
    { 
      title: '2. Siga sumando', 
      image: 'Step_2',
      desc: 'Superada la primera misión, se activa la segunda.<strong> Cada vez que la cumpla, ganará 100 puntos</strong> para seguir acumulando.',
    },
    { 
      title: '3. Tabla de posiciones', 
      image: 'Step_3',
      desc: '<strong>Los 1.500 participantes con más puntos</strong> al final de la campaña, ganarán. ¡No se pierda las misiones secretas que lo ayudarán a escalar posiciones más rápido!',
    },
  ]

  const slidesRewards = [
    {  
      image: '/assets/images/rewards_1.png',
      desc: '<strong>5 participantes ganarán</strong> experiencias para dos personas hasta por $15.000.000.',
    },
    {  
      image: '/assets/images/rewards_2.png',
      desc: '<strong>30 participantes ganarán</strong> bonos de hasta $7.000.000 en Jumbo, Falabella o Ktronix.',
    },
    {  
      image: '/assets/images/rewards_3.png',
      desc: '<strong>1.465 participantes</strong> podrán llevarse bonos de hasta $500.000 en los comercios disponibles.',
    },
  ]

  return (
    <div className='mc-page mechanic slow_ani'>
      <div className="mc-page__title--container">
        <h1>
          <span className="mc-page__title">
            <span className="mc-page__title--red">¿Cómo participar?</span>
          </span>
        </h1>
        <span className="mc-page__subtitle">Complete las misiones, gane puntos y podrá participar por premios.</span>
      </div>
      <Container>
        <div className='participar-steps-container'>
          {slidesSteps.map((step, index) => {   
            return (
              <div key={index} className={`participar-steps__items--item`}>
                <div className='participar-steps__items--item--title'>
                  <svg className='svg-type2' xmlns="http://www.w3.org/2000/svg" width="47" height="58" viewBox="0 0 47 58" fill="none">
                    <path d="M-42.0209 27.5242C-46.8424 20.3517 -42.4501 14.599 -37.6689 12.6663C-22.6055 6.61382 25.3896 -7.81215 39.4468 6.18844C54.0262 19.5765 41.7613 69.3932 36.1225 84.5949C32.8814 93.2953 25.4791 90.4398 20.0217 87.1478C0.0141109 74.9629 -23.2747 55.3209 -42.0209 27.5242Z" fill="#FFC5C8"/>
                  </svg>
                  <h6>
                    <Icon name={step.image}/> {step.title}
                  </h6>
                </div>
                <div className='participar-steps__items--item--desc'>
                  <p dangerouslySetInnerHTML={{ __html: step.desc}} />
                </div>
              </div>
            )  
          })}
        </div>
        <div className='participar-rewards'>
          <div className= 'participar-rewards-container'>
            <div className='participar-rewards-title'>
              <svg className='svg-type2' xmlns="http://www.w3.org/2000/svg" width="47" height="58" viewBox="0 0 47 58" fill="none">
                <path d="M-42.0209 27.5242C-46.8424 20.3517 -42.4501 14.599 -37.6689 12.6663C-22.6055 6.61382 25.3896 -7.81215 39.4468 6.18844C54.0262 19.5765 41.7613 69.3932 36.1225 84.5949C32.8814 93.2953 25.4791 90.4398 20.0217 87.1478C0.0141109 74.9629 -23.2747 55.3209 -42.0209 27.5242Z" fill="#FFC5C8"/>
              </svg>
              <h4>
                <img src={`/assets/images/login_rewards.png`} alt={'login rewards img'} /> Premios por posición
              </h4>
              <span>
                Consulte qué premios recibirá según su ubicación en la tabla de posiciones al final de Misión Debitazo.
              </span>
            </div>
            <div className='participar-rewards-desc'>
              <Slider {...sliderRewardsSettings}>
                {slidesRewards.map((step, index) => {   
                  return (
                    <div key={index} className={`participar-rewards__items--item`}>
                      <div className='participar-rewards__items--item--img'>
                        <img src={step.image} alt={step.image} />
                      </div>
                      <div className='participar-rewards__items--item--desc'>
                        <span dangerouslySetInnerHTML={{ __html: step.desc}} />
                      </div>
                    </div>
                  )  
                })}
              </Slider>
              <div className='participar-button-container'>
                <Button
                  type="primary"
                  text="Conozca todos los premios"
                  status="active"
                  link="/premios"
                  datalayer={{event: 'guidelines', text: 'Conozca todos los premios', link: '/premios' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      { context && context.quiz && (context.quiz.length === 0 || context.quiz.every(q => q.response_3 === null)) &&
        <div className='mc-section-testForm'>
          <TestQuestions context={context} quizNumber={2} />
        </div>
      }
    </div>
  )
}
export default Mechanic